import jwt from 'jsonwebtoken';

import {
    REQUEST,
    FAILURE,
    LOGIN_SUCCESS,
    LOGOUT,
    CHANGE_PASSWORD,
} from './actions';

export const getParsedLSItemOr = (key, fallback, isInt) => {
    const value = localStorage.getItem(key);
    let parsedValue = null;

    try {
        parsedValue = JSON.parse(value);
    } catch (e) {
        console.warn(
            'Encountered error while parsing localStorage in Auth reducer. Clearing localStorage.',
            e,
            localStorage,
        );
        localStorage.clear();
    }

    if(!value) return fallback;

    return isInt ? parseInt(parsedValue, 10) : parsedValue;
};

const initialState = {
    isLoading: false,
    error: null,
    verified: undefined,
    user: getParsedLSItemOr('user', null),
    isAuthenticated: !!localStorage.getItem('access_token'),
    masterUser: getParsedLSItemOr('is_masteruser', false),
    organizationID: getParsedLSItemOr('organization_id', null, true),
    companyID: getParsedLSItemOr('company_id', null, true),
    isFwee: getParsedLSItemOr('is_fweewawe', false),
    isOrganization: !!getParsedLSItemOr('organization_id', false, true),
};

export default function auth(state = initialState, action) {
    switch(action.type) {
        case REQUEST: {
            return {
                ...state,
                isLoading: false,
            };
        }
        case FAILURE: {
            return {
                ...state,
                isLoading: false,
                error: action.errorMessage,
            };
        }
        case LOGIN_SUCCESS: {
            const {
                organization_id,
                is_master,
                access_token,
                public_key,
                refresh_token,
                company_id,
                freeware_id,
            } = action.response;
            const isOrganization = !!organization_id;
            const isFwee = !!freeware_id;
            const { user } = jwt.verify(access_token, public_key);
            // { || null }, because { undefined } is not a valid JSON value
            localStorage.setItem('user', JSON.stringify(user));
            localStorage.setItem('access_token', access_token || null);
            localStorage.setItem('refresh_token', refresh_token || null);
            localStorage.setItem('public_key', public_key || null);
            localStorage.setItem('is_masteruser', is_master || null);
            localStorage.setItem('organization_id', organization_id || null);
            localStorage.setItem('company_id', company_id || null);
            localStorage.setItem('is_fweewawe', isFwee || null);

            if(isFwee) import('styles/fwee.scss'); // eslint-disable-line no-unused-expressions

            return {
                ...state,
                isAuthenticated: true,
                user,
                isLoading: false,
                error: null,
                isOrganization,
                // isCompany,
                masterUser: is_master,
                organizationID: organization_id,
                companyID: company_id,
                isFwee,
            };
        }
        case LOGOUT: {
            localStorage.removeItem('oauth2');
            localStorage.removeItem('icon');

            // These should match "LOGIN_SUCCESS" case's setItem() -calls exactly.
            localStorage.removeItem('user');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
            localStorage.removeItem('public_key');
            localStorage.removeItem('is_masteruser');
            localStorage.removeItem('organization_id');
            localStorage.removeItem('company_id');
            localStorage.removeItem('is_fweewawe');

            return {
                ...state,
                error: action.data,
                token: null,
                user: null,
                isAuthenticated: false,
                isLoading: false,
                isOrganization: null,
                masterUser: false,
                isFwee: false,
            };
        }
        case CHANGE_PASSWORD: {
            const response = jwt.verify(action.response.access_token, action.response.public_key);

            localStorage.setItem('user', JSON.stringify(response.user));
            localStorage.setItem('access_token', action.response.access_token);
            localStorage.setItem('refresh_token', action.response.refresh_token);
            localStorage.setItem('public_key', action.response.public_key);

            return {
                ...state,
                success: 'Password has been updated succesfully',
                change: true,
            };
        }
        default: { return state; }
    }
}
