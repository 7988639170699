import moment from 'moment';

export const USERNAME_PATTERN = /^[\w@+.-]+$/i; // Django username regex
export const EMAIL_PATTERN = /^[\w+.]+@[a-z0-9-]+\.[a-z0-9-]{2,63}$/i; // Similar (simpler) to django email regex
export const BACKEND_DATETIME_FORMAT = moment.ISO_8601;
export const GOOGLE_CAPTCHA_SITE_KEY = '6Lc0n3kaAAAAACcNZy34NKWilMJRpdTXHF_4rIZp';
export const GA_KEY = 'G-D189Z1CX0Z';

export const SERVER = (process.env.NODE_ENV === 'production' && window.react?.REACT_APP_API_URL)
    ? window.react.REACT_APP_API_URL
    : process.env.REACT_APP_API_URL;

export const API = {
    auth: {
        key: `${SERVER}auth/key/`,
        token: `${SERVER}auth/`,
        refresh: `${SERVER}auth/refresh/`,
        logout: `${SERVER}auth/logout/`,
        reset: `${SERVER}auth/reset/`,
    },
    fleets: {
        list: `${SERVER}fleets/`,
        base: `${SERVER}fleet/`,
        detail: `${SERVER}fleet/:id/`,
        pdf: `${SERVER}fleet/:id/pdf/`,
        copy_cars: `${SERVER}fleets/cars/copy/`,
        copy_label: `${SERVER}fleets/labels/copy/`,
        move_vehicles: `${SERVER}fleets/cars/move/`,
        copy_vehicles: `${SERVER}fleets/cars/copy/`,
        share_send: `${SERVER}fleets/share/send/`,
        share_accept: `${SERVER}fleets/share/accept/`,
        share_reject: `${SERVER}fleets/share/reject/`,
    },
    labels: {
        list: `${SERVER}labels/`,
        detail: `${SERVER}label/:id/`,
        fleets: `${SERVER}label/:id/fleets/`,
    },
    vehicles: {
        list: `${SERVER}cars/`,
        detail: `${SERVER}car/:id/`,
        initial: `${SERVER}car/initial-data/`,
        convert: `${SERVER}car/:id/convert/`,
        data: `${SERVER}car-data-validation/`,
        CSV: `${SERVER}cars/csv-import/`,
        edit_car_data: `${SERVER}car-data/:id/`,
        generate_qr: `${SERVER}cars/qr-code/pdf/`,
        car_data_sources: `${SERVER}car-data/:id/fields-source/`,
        car_spec_sources: `${SERVER}car-spec/:id/fields-source/`,
        nedc_file_car_data: `${SERVER}car-data/:id/nedc-file/`,
        wltp_file_car_data: `${SERVER}car-data/:id/wltp-file/`,
        class_file_car_data: `${SERVER}car-data/:id/class-file/`,
        class_file_car_spec: `${SERVER}car-spec/:id/class-file/`,
        edit_car_spec: `${SERVER}car-spec/:id/`,
        traficom_kinds: `${SERVER}traficom/kind/list/`,
        add_by_kind: `${SERVER}car/`,
        // v These also support 'freeware/' and 'public/' prefixes. Defined in actions
        make: `${SERVER}:prefix/make/:clean/`,
        default: `${SERVER}make/default/`,
        model_type: `${SERVER}:prefix/model-type/:clean/:make/:year/`,
        year: `${SERVER}:prefix/year/:clean/:make/:model_type/`,
        mmty_key: `${SERVER}:prefix/mmty/:clean/:make/:model_type/:year/`,
    },
    dashboard: {
        base: `${SERVER}dashboard/`,
        emissions: `${SERVER}dashboard/target-emissions-fuel-types/`,
        notifications: `${SERVER}notifications/`,
        notification: `${SERVER}notification/:id/`,
        notifications_popup: `${SERVER}notifications/popup/`,
        fuel_types: `${SERVER}dashboard/fuel-map/`,
        error_notification: `${SERVER}notification/:id/csv-file/`,
        refuel: `${SERVER}dashboard/refuel/`,
    },
    public: {
        captcha: `${SERVER}public/recaptcha/`,
        MMT: `${SERVER}public/make-model-type/`,
    },
    mileage: {
        list: `${SERVER}mileages/`,
        events: `${SERVER}mileage/events/`,
        initial_cars: `${SERVER}mileage/initial-data/cars/`,
        initial_fleets: `${SERVER}mileage/initial-data/fleets/`,
        csv: `${SERVER}mileage/csv-import/`,
        csv_template: `${SERVER}mileage/csv-template/`,
        csv_refuel: `${SERVER}refuel/csv-import/`,
        csv_refuel_template: `${SERVER}refuel/csv-template/`,
        driving_journal: `${SERVER}mileage/csv-export/`,
        summary: `${SERVER}report/mileage-summary/`,
        report: {
            base: `${SERVER}mileage/report/`,
            pdf: `${SERVER}mileage/report/pdf/`,
            csv: `${SERVER}mileage/report/csv-export/`,
            templates: `${SERVER}mileage/report/template/`,
            // templateDetail: `${SERVER}mileage/report/template/:id/`,
        },
    },
    reports: {
        list: `${SERVER}reports/`,
        pdf: `${SERVER}reports/pdf/`,
        csv: `${SERVER}report/csv-zip/`,
        xls: `${SERVER}report/xls-zip/`,
        mileage: `${SERVER}report/mileage/`,
        vehicle: `${SERVER}report/cars/general/`,
        vehicle_emissions: `${SERVER}report/cars/mileages_emissions/`,
        fleet: `${SERVER}report/fleet/`,
        fleets: `${SERVER}report/fleets/general/`,
        general_mileage_emission: `${SERVER}report/general/mileages_emissions/`,
        chosen_mileage_fleets: `${SERVER}report/fleets/chosen/mileages_emissions/`,
        chosen_mileage_cars: `${SERVER}report/cars/chosen/mileages_emissions/`,
        selected_vehicles: `${SERVER}report/cars/selected/`,
        working_machines: `${SERVER}report/machines/`,
        cvd: `${SERVER}report/cars/clean-vehicle-directive/`,
    },
    help: {
        videos: `${SERVER}help/videos/:locale`,
        faq: `${SERVER}help/faq/:locale`,
        message: `${SERVER}help/contact-us`,
    },
    user: {
        base: `${SERVER}user/`,
        profile: `${SERVER}user/profile/`,
        icon: `${SERVER}user/icon/`,
        tier: `${SERVER}user/tier/`,
        activate: `${SERVER}user/activate/:token/`,
        change: `${SERVER}user/password/`,
        reset: `${SERVER}user/password/reset/`,
        new_password: `${SERVER}user/password/set/:token/`,
        register: `${SERVER}user/register/`,
        reset_cvm: `${SERVER}driver/user/password/reset/`,
    },
    cvm: {
        list: `${SERVER}:org_or_company/driver/list/`,
        create: `${SERVER}:org_or_company/driver/create/`,
        delete: `${SERVER}:org_or_company/driver/delete/:id/`,
        mileage: `${SERVER}config/mileage_fields/`,
        journal_car: `${SERVER}mileage/journal/car/list/`,
        journal_user: `${SERVER}mileage/journal/driver/list/`,
        journal_mileage: `${SERVER}mileage/journal/list/filter/:date/`,
        journal_edit: `${SERVER}mileage/journal/:id/`,
    },
    booking: {
        book: `${SERVER}manager/book/`,
        list: `${SERVER}manager/book/list`,
        cars: `${SERVER}manager/book/search/`,
        users: `${SERVER}manager/book/driver/list/`,
        update: `${SERVER}manager/book/:id/`,
    },
    groups: `${SERVER}organizations-and-companies/`,
    countries: `${SERVER}enum/countries/`,
    events: `${SERVER}events/`,
    fweewawe: {
        dashboard: {
            base: `${SERVER}freeware/dashboard/`,
            emissions: `${SERVER}freeware/dashboard/target-emissions-fuel-types/`,
        },
        reports: {
            list: `${SERVER}freeware/reports/`,
            pdf: `${SERVER}freeware/reports/pdf/`,
        },
        vehicles: {
            base: `${SERVER}freeware/car/`, // TODO: Unused ?
            list: `${SERVER}freeware/cars/`,
            detail: `${SERVER}freeware/car/:id/`,
            MMT: `${SERVER}freeware/make-model-type/`,
        },
        fleets: {
            base: `${SERVER}freeware/fleet/`,
            list: `${SERVER}freeware/fleets/`,
            detail: `${SERVER}freeware/fleet/:id/`,
        },
    },
    headers: {
        'Content-Type': 'application/json',
        'App-Version': '1.1.0',
        'Client-App': 'app',
    },
    fileHeaders: {
        'Content-Type': 'multipart/form-data',
        'App-Version': '1.1.0',
        'Client-App': 'app',
    },
};
