import { COLOURS, SORTS, MILEAGE_COMPARISONS, VEHICLE_CLASS_SORTS } from 'components/Dashboard/utils.js';
import { searchFields, sortVehicles } from '../Fleets/utils';
import { GET_FLEETS, GET_LABELS } from '../Fleets/actions.js';
import {
    SET_DASHBOARD_SEARCH,
    GET_DASHBOARD_DATA_SUCCESS,
    GET_DASHBOARD_DATA_FAILURE,
    GET_DASHBOARD_NOTIFICATIONS,
    READ_DASHBOARD_NOTIFICATION,
    SET_DASHBOARD_COMPARISON,
    GET_TARGET_EMISSIONS,
    SET_TARGET_EMISSIONS,
    SET_TARGET_FUELS,
    SET_VEHICLE_CLASS_SORT,
    GET_FUEL_TYPES,
} from './actions.js';

export const sortData = (data, sortFn) => [...data].sort(sortFn);

const initialState = {
    fleets: [],
    labels: [],
    vehicles: [],
    comparedData: [],
    sort: SORTS()[2],
    compared: MILEAGE_COMPARISONS()[0],
    searchText: '',
    averageTotalMileage: null,
    averageAllEmissions: 0,
    averageAllClean: 0,
    targetEmissions: null,
    cleanPercentageCharts: [],
    cleanTarget: null,
    vehicleCount: 0,
    totalLightVehicles: 0,
    totalHeavyVehicles: null,
    vehicleCountEmissions: 0,
    vehicleClasses: [],
    acceptableFuels: null,
    cleanLight: null,
    cleanHeavy: 0,
    qualifiedLight: 0,
    qualifiedHeavy: 0,
    allVehicleClasses: [],
    vehicleClassSort: 'cars_number', // value from VEHICLE_CLASS_SORTS
    fuelCategories: null,
    scoring: {},
    loading: true,
};

function dashboard(state = initialState, action) {
    switch(action.type) {
        case GET_FLEETS: {
            return {
                ...state,
                fleets: action.response,
            };
        }
        case SET_DASHBOARD_COMPARISON: {
            const { compared, sort } = action;
            const { searchText } = state;

            const newSort = SORTS().find(({ value }) => value === sort);

            const comparedData = sortData(
                state[compared.category],
                newSort.fn(compared.sortKeyNames[newSort.value]),
            );

            return {
                ...state,
                sort: newSort,
                compared,
                comparedData: searchFields(comparedData, searchText, ['name', 'plate']),
            };
        }
        case SET_DASHBOARD_SEARCH: {
            const { text } = action;
            const { compared, sort } = state;
            const { category } = compared;

            return {
                ...state,
                searchText: text,
                comparedData: sortData(
                    searchFields(state[category], text, ['name', 'plate']),
                    sort.fn(compared.sortKeyNames[sort.value]),
                ),
            };
        }
        case GET_DASHBOARD_DATA_SUCCESS: {
            const { vehicleClassSort, compared, sort } = state;

            const {
                target_emissions: targetEmissions,
                average_emissions: averageAllEmissions,
                clean_percentage: averageAllClean,
                average_km: averageTotalMileage,
                last_week_average_km: averageWeekMileage,
                summary_by_fleets: fleets,
                summary_by_labels: labels,
                summary_by_cars: vehicles,
                // ^ Above for comparison chart, v below for other stuff
                total_vehicles_count: vehicleCount,
                vehicle_classes: vehicleClassesWithoutColors,
                daily_percentage_of_clean_vehicles: cleanPercentageCharts,
                default_clean_percent: cleanTarget,
                target_fuel_types: acceptableFuels,
                percentage_clean_light_vehicles: cleanLight,
                percentage_clean_heavy_vehicles: cleanHeavy,
                percentage_qualified_light_vehicles: qualifiedLight,
                percentage_qualified_heavy_vehicles: qualifiedHeavy,
                all_vehicle_classes: allVehicleClasses,
                total_light_vehicles: totalLightVehicles,
                total_heavy_vehicles: totalHeavyVehicles,
                // ^ Above for other stuff, v below for CVW index stuff
                cvw_index,
                average_emissions,
                average_emissions_light_vehicles,
                average_emissions_heavy_vehicles,
                total_emissions,
                clean_percentage,
                total_km,
                last_week_total_emissions,
                last_week_total_km,
            } = action.response;

            const vehicleClasses = sortVehicles(
                vehicleClassesWithoutColors.map((type, i) => ({ ...type, color: COLOURS[i] })),
                vehicleClassSort,
                VEHICLE_CLASS_SORTS().find(({ value }) => value === vehicleClassSort).order || 1,
            );

            const categories = { labels, fleets, vehicles };
            const comparedData = sortData(categories[compared.category], sort.fn(compared.sortKeyNames[sort.value]));

            return {
                ...state,
                averageAllEmissions,
                averageAllClean,
                cleanLight,
                cleanHeavy,
                qualifiedLight,
                qualifiedHeavy,
                targetEmissions,
                cleanPercentageCharts,
                cleanTarget,
                vehicleCount,
                vehicleClasses,
                fleets,
                labels,
                acceptableFuels,
                allVehicleClasses,
                totalLightVehicles,
                totalHeavyVehicles,
                vehicles,
                averageMileage: [averageTotalMileage, averageWeekMileage],
                comparedData,
                loading: false,
                scoring: {
                    cvw_index,
                    average_emissions,
                    average_emissions_light_vehicles,
                    average_emissions_heavy_vehicles,
                    total_emissions,
                    clean_percentage,
                    total_km,
                    last_week_total_emissions,
                    last_week_total_km,
                },
            };
        }
        case GET_LABELS: {
            const { searchText, sort } = state;
            const labels = action.response;

            return {
                ...state,
                labels: sortData(searchFields(labels, searchText, ['name']), sort.fn),
            };
        }
        case GET_TARGET_EMISSIONS: {
            return {
                ...state,
                targetEmissions: action.response.target_emissions,
                acceptableFuels: action.response.target_fuel_types,
            };
        }
        case GET_DASHBOARD_NOTIFICATIONS: {
            return { ...state, notifications: action.response.filter(n => !n.dismiss) };
        }
        case READ_DASHBOARD_NOTIFICATION: {
            return {
                ...state,
                notifications: state.notifications.filter(n => n.id !== action.response.id),
            };
        }
        case SET_TARGET_EMISSIONS:
        case SET_TARGET_FUELS: {
            const { vehicleClassSort } = state;
            const {
                target_emissions: targetEmissions,
                vehicle_classes,
                /* percentage_qualified_vehicles: qualified, */
                percentage_clean_light_vehicles: cleanLight,
                percentage_clean_heavy_vehicles: cleanHeavy,
                percentage_qualified_light_vehicles: qualifiedLight,
                percentage_qualified_heavy_vehicles: qualifiedHeavy,
                total_vehicles_count: vehicleCount,
                target_fuel_types: acceptableFuels,
                em_avg_and_perc_clean_by_fleets: fleets,
                em_avg_and_perc_clean_by_labels: labels,
            } = action.response;

            const vehicleClasses = sortVehicles(
                vehicle_classes.map((type, i) => ({ ...type, color: COLOURS[i] })),
                vehicleClassSort,
                VEHICLE_CLASS_SORTS().find(({ value }) => value === vehicleClassSort).order || 1,
            );

            return {
                ...state,
                targetEmissions,
                // qualified,
                cleanLight,
                cleanHeavy,
                qualifiedLight,
                qualifiedHeavy,
                vehicleCount,
                acceptableFuels,
                fleets,
                labels,
                vehicleClasses,
            };
        }
        case SET_VEHICLE_CLASS_SORT: {
            const { newSort } = action;

            const vehicleClasses = sortVehicles(
                state.vehicleClasses,
                newSort,
                VEHICLE_CLASS_SORTS().find(({ value }) => value === newSort).order || 1,
            );

            return {
                ...state,
                vehicleClassSort: newSort,
                vehicleClasses,
            };
        }
        case GET_FUEL_TYPES: {
            const { fuel_categories } = action.response;

            return { ...state, fuelCategories: fuel_categories };
        }
        case GET_DASHBOARD_DATA_FAILURE: {
            return { ...state, loading: false };
        }
        default: { return state; }
    }
}

export default dashboard;
