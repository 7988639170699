import { t } from 'ttag';
import { CHANGE_LOCALE } from 'components/layouts/actions.js';
import { searchFields, updateColumnSorts, sortVehicles, formatCarObject } from './utils.js';
import {
    GET_FLEETS,
    NEW_FLEET,
    RENAME_FLEET,
    DELETE_FLEET,
    SET_FLEET_SORT,
    GET_LABELS,
    GET_LABEL_FLEETS,
    COPY_FLEETS_TO_LABELS,
    RENAME_LABEL,
    UPDATE_FLEET_LABELS,
    DELETE_LABEL,
    SET_LABEL_COLOR,
    NEW_LABEL,
    REMOVE_FLEETS,
    REMOVE_VEHICLES,
    DELETE_FLEETS,
    SET_FLEET_COLUMNS,
    SET_FLEET_CARS,
    GET_FLEET_DETAILS,
    SET_VEHICLE_WLTP,
    SET_VEHICLE_NEDC,
    SET_VEHICLE_CLASS,
    SET_VEHICLE_FUEL,
    SET_VEHICLE_PLATE,
    SET_VEHICLE_PROCURED_AT,
    RENAME_VEHICLE,
    SET_VEHICLE_INSPECTION_START,
    SET_VEHICLE_INSPECTION_END,
    SET_VEHICLE_INSPECTION_LAST,
    SET_VEHICLE_CPVS,
    GET_CUSTOMERS,
    SHARE_FLEET,
    ACCEPT_SHARED_FLEET,
    REJECT_SHARED_FLEET,
} from './actions.js';

export const initialFleetColumns = () => [
    { label: t`FLEET_NAME`, field: 'name', order: 1 },
    { label: `${t`FLEETS_COLUMN_EMISSIONS`} (g/km)`, field: 'emissions_average', order: 1 },
    { label: t`VEHICLE_NUMBERS`, field: 'cars_count', order: 1 },
    { label: t`CLEAN_VEHICLES_(%)`, field: 'clean_cars_perc', order: 1 },
    { label: t`FLEETS_COLUMN_CREATED`, field: 'created_at', order: 1 },
    // TODO: When backend implements fleet.last_edited_by
    // { label: t`EDITED_BY`, field: 'last_edited_by', order: 1 },
    { label: t`LABELS`, field: 'labels', order: 1 },
];

const initialState = {
    allFleets: [],
    listFleets: [],
    fleetSort: 'name',
    searchText: '',
    labels: [],
    fleetColumns: [],
    customers: [],
};

function fleets(state = initialState, action) {
    switch(action.type) {
        case GET_FLEETS: {
            const { fleetSort, fleetColumns } = state;
            const sortedColumn = fleetColumns.find(col => col.field === fleetSort);
            const order = sortedColumn ? sortedColumn.order : 1;
            const fleetsWithFormattedCars = action.response.map(fleet => ({
                ...fleet,
                cars: fleet.cars.map(formatCarObject),
            }));

            return {
                ...state,
                allFleets: fleetsWithFormattedCars,
                listFleets: action.updateList
                    ? sortVehicles(fleetsWithFormattedCars, fleetSort, order)
                    : state.listFleets,
            };
        }
        case NEW_FLEET: {
            return {
                ...state,
                allFleets: [
                    ...state.allFleets,
                    action.response,
                ],
            };
        }
        case RENAME_FLEET: {
            const updatedFleets = [...state.allFleets];
            const fleetToUpdateIndex = updatedFleets.findIndex(fleet => fleet.id === action.response.id);

            updatedFleets[fleetToUpdateIndex] = { ...action.response, cars: action.response.cars.map(formatCarObject) };
            return {
                ...state,
                allFleets: updatedFleets,
            };
        }
        case DELETE_FLEET: {
            const newFleets = [...state.allFleets];
            const indexToDelete = newFleets.findIndex(fleet => fleet.id === parseInt(action.id, 10));
            newFleets.splice(indexToDelete, 1);

            return {
                ...state,
                allFleets: newFleets,
            };
        }
        case SET_FLEET_SORT: {
            const { sort } = action;
            const { newColumns, columnNewOrder } = updateColumnSorts(state.fleetColumns, state.fleetSort, sort);

            return {
                ...state,
                listFleets: sortVehicles(state.listFleets, sort, columnNewOrder),
                fleetSort: sort,
                fleetColumns: newColumns,
            };
        }
        case GET_LABELS: {
            return {
                ...state,
                labels: action.response.sort((a, b) => a.id - b.id),
            };
        }
        case GET_LABEL_FLEETS: {
            return {
                ...state,
                listFleets: searchFields(action.response, state.searchText, ['name']),
            };
        }
        case RENAME_LABEL: {
            const labels = [...state.labels];
            labels.find(({ id }) => action.response.id === id).name = action.response.name;

            return {
                ...state,
                labels,
            };
        }
        case UPDATE_FLEET_LABELS: {
            const { response } = action;
            const { id, label_ids, cars } = response;

            response.cars = cars.map(formatCarObject);

            const allFleets = state.allFleets.map(fleet => (fleet.id === id ? response : fleet));
            const listFleets = state.listFleets.map(fleet => (fleet.id === id ? response : fleet));

            const labels = state.labels.map(label => {
                // Either remove or add the fleet to label's fleet_ids based on the returned label_ids
                const updatedFleetHasLabel = label_ids.includes(label.id);
                const oldFleetHasLabel = label.fleet_ids.includes(id);

                let { fleet_ids } = label;
                if(updatedFleetHasLabel && !oldFleetHasLabel) fleet_ids = fleet_ids.concat(id);
                if(!updatedFleetHasLabel && oldFleetHasLabel) fleet_ids = fleet_ids.filter(f => f.id !== id);

                return ({ ...label, fleet_ids });
            });

            return { ...state, allFleets, listFleets, labels };
        }
        case DELETE_LABEL: {
            const labels = [...state.labels];
            const labelIndex = labels.findIndex(({ id }) => id === action.id);
            labels.splice(labelIndex, 1);

            return {
                ...state,
                labels,
            };
        }
        case SET_LABEL_COLOR: {
            const { color, id } = action.response;

            // Set new label color in state.labels
            const labels = [...state.labels];
            labels.find(label => label.id === action.response.id).color = color;

            // Set new label color for all fleets in state.allFleets
            const allFleets = state.allFleets.map(fleet => {
                // Make a copy of fleet labels
                const fleetLabels = [...fleet.labels];
                // Find index of label with the right id
                const labelIndex = fleetLabels.findIndex(label => label.id === id);

                // If this fleet is under the label whose color was changed, set the new color
                if(labelIndex !== -1) {
                    fleetLabels[labelIndex].color = color;
                }

                return {
                    ...fleet,
                    labels: fleetLabels,
                };
            });

            // Set a new label color for all list fleets in state.listFleets
            const listFleets = state.listFleets.map(fleet => {
                // Make a copy of fleet labels
                const fleetLabels = [...fleet.labels];
                // Find index of label with the right id
                const labelIndex = fleetLabels.findIndex(label => label.id === id);

                // If this fleet is under the label whose color was changed, set the new color
                if(labelIndex !== -1) {
                    fleetLabels[labelIndex].color = color;
                }

                return {
                    ...fleet,
                    labels: fleetLabels,
                };
            });

            return {
                ...state,
                labels,
                allFleets,
                listFleets,
            };
        }
        case NEW_LABEL: {
            return {
                ...state,
                labels: [
                    ...state.labels,
                    action.response,
                ],
            };
        }
        case COPY_FLEETS_TO_LABELS: {
            const responseLabels = action.response.label_ids;
            const allLabelIDs = state.labels.map(({ id }) => id);
            // Precautionarily map to parseInt, because backend responds with strings if we accidentally send strings
            const fleetIDs = action.response.fleet_ids.map(id => parseInt(id, 10));

            const allFleets = [...state.allFleets];
            allFleets.forEach((fleet, i) => {
                if(fleetIDs.includes(fleet.id)) {
                    allFleets[i].label_ids = [
                        ...fleet.label_ids,
                        ...allLabelIDs.filter(id => responseLabels.includes(id) && fleet.label_ids.indexOf(id) === -1),
                    ];
                }
            });

            const listFleets = [...state.listFleets];
            listFleets.forEach((fleet, i) => {
                if(fleetIDs.includes(fleet.id)) {
                    listFleets[i].label_ids = [
                        ...fleet.label_ids,
                        ...allLabelIDs.filter(id => responseLabels.includes(id) && fleet.label_ids.indexOf(id) === -1),
                    ];
                }
            });

            const labels = [...state.labels];
            labels.forEach((label, i) => {
                labels[i].fleet_ids = [
                    ...label.fleet_ids,
                    ...fleetIDs.filter(id => responseLabels.includes(label.id) && !label.fleet_ids.includes(id)),
                ];
            });

            return {
                ...state,
                allFleets,
                listFleets,
                labels,
            };
        }
        case REMOVE_FLEETS: {
            const { id, fleet_ids } = action.response;
            const listFleets = state.listFleets.filter(({ id }) => action.response.fleet_ids.includes(id));
            const updatedLabels = state.labels.map(label => (
                label.id === id ? { ...label, fleet_ids } : label
            ));
            return {
                ...state,
                listFleets,
                labels: updatedLabels,
            };
        }
        case REMOVE_VEHICLES: {
            const allFleets = state.allFleets.map(fleet => (fleet.id === action.id
                ? { ...action.response, cars: action.response.cars.map(formatCarObject) }
                : fleet));
            const listFleets = state.listFleets.map(fleet => (fleet.id === action.id
                ? { ...action.response, cars: action.response.cars.map(formatCarObject) }
                : fleet));

            return {
                ...state,
                allFleets,
                listFleets,
            };
        }
        case DELETE_FLEETS: {
            const { fleetIDs } = action;
            const allFleets = state.allFleets.filter(({ id }) => !fleetIDs.includes(id));
            const listFleets = state.listFleets.filter(({ id }) => !fleetIDs.includes(id));
            const labels = state.labels.map(label => ({
                ...label,
                fleet_ids: label.fleet_ids.filter(id => !fleetIDs.includes(id)),
            }));

            return {
                ...state,
                allFleets,
                listFleets,
                labels,
            };
        }
        case CHANGE_LOCALE: {
            // TODO: set current columns in a way it runs the translations
            return {
                ...state,
                fleetColumns: initialFleetColumns(),
            };
        }
        case SET_FLEET_COLUMNS: {
            return {
                ...state,
                fleetColumns: action.fleetColumns,
            };
        }
        case SET_FLEET_CARS: {
            const allFleets = state.allFleets.map(fleet => (fleet.id === action.id
                ? { ...action.response, cars: action.response.cars.map(formatCarObject) }
                : fleet));
            const listFleets = state.listFleets.map(fleet => (fleet.id === action.id
                ? { ...action.response, cars: action.response.cars.map(formatCarObject) }
                : fleet));

            return {
                ...state,
                allFleets,
                listFleets,
            };
        }
        case GET_FLEET_DETAILS: {
            const allFleets = state.allFleets.map(fleet => (fleet.id === action.id
                ? { ...action.response, cars: action.response.cars.map(formatCarObject) }
                : fleet));
            const listFleets = state.listFleets.map(fleet => (fleet.id === action.id
                ? { ...action.response, cars: action.response.cars.map(formatCarObject) }
                : fleet));

            return {
                ...state,
                allFleets,
                listFleets,
            };
        }
        case SET_VEHICLE_CLASS:
        case SET_VEHICLE_NEDC:
        case SET_VEHICLE_WLTP:
        case SET_VEHICLE_FUEL:
        case SET_VEHICLE_PLATE:
        case SET_VEHICLE_PROCURED_AT:
        case SET_VEHICLE_INSPECTION_START:
        case SET_VEHICLE_INSPECTION_END:
        case SET_VEHICLE_INSPECTION_LAST:
        case SET_VEHICLE_CPVS:
        case RENAME_VEHICLE: {
            const { id, response } = action;
            const updateCarInFleet = fleet => (fleet.car_ids.includes(id)
                ? {
                    ...fleet,
                    cars: fleet.cars.map(car => (car.id === id
                        ? { ...car, ...formatCarObject(response), id }
                        : car
                    )),
                }
                : fleet
            );

            const allFleets = state.allFleets.map(updateCarInFleet);
            const listFleets = state.listFleets.map(updateCarInFleet);

            return {
                ...state,
                allFleets,
                listFleets,
            };
        }
        case GET_CUSTOMERS: {
            return { ...state, customers: action.response.organizations_and_companies };
        }
        case SHARE_FLEET: {
            const {
                allFleets = { ...allFleets },
                listFleets = { ...listFleets },
            } = state;

            action.response.forEach(fleet => {
                fleet = { ...fleet, cars: fleet.cars.map(formatCarObject) };

                const allFleetsIndex = allFleets.findIndex(f => f.id === fleet.id);
                const listFleetsIndex = listFleets.findIndex(f => f.id === fleet.id);

                if(allFleetsIndex === -1) allFleets.push(fleet);
                else allFleets.splice(allFleetsIndex, 1, fleet);

                if(listFleetsIndex === -1) listFleets.push(fleet);
                else listFleets.splice(listFleetsIndex, 1, fleet);
            });

            return { ...state, allFleets, listFleets };
        }
        case ACCEPT_SHARED_FLEET: {
            const fleet = { ...action.response[0], cars: action.response[0].cars.map(formatCarObject) };

            const allFleets = [...state.allFleets];
            const allFleetsIndex = allFleets.findIndex(_fleet => _fleet.id === fleet.id);
            allFleets.splice(allFleetsIndex, 1, fleet);

            const listFleets = [...state.listFleets];
            const listFleetsIndex = listFleets.findIndex(_fleet => _fleet.id === fleet.id);
            listFleets.splice(listFleetsIndex, 1, fleet);

            return { ...state, allFleets, listFleets };
        }
        case REJECT_SHARED_FLEET: {
            const { id } = action.response;

            const allFleets = [...state.allFleets];
            const allFleetsIndex = allFleets.findIndex(fleet => fleet.id === id);
            allFleets.splice(allFleetsIndex, 1);

            const listFleets = [...state.listFleets];
            const listFleetsIndex = listFleets.findIndex(fleet => fleet.id === id);
            listFleets.splice(listFleetsIndex, 1);

            return { ...state, allFleets, listFleets };
        }
        default: { return state; }
    }
}

export default fleets;
