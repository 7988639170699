import {
    REQUEST,
    FAILURE,
    LIST_DRIVERS,
    EDIT_DRIVERS,
    DELETE_DRIVER,
    GET_MILEAGE_FIELDS,
    SET_MILEAGE_FIELDS,
    GET_JOURNAL_CARS,
    GET_JOURNAL_USERS,
    GET_JOURNAL_MILEAGE,
    UPDATE_MILEAGE,
    DELETE_MILEAGE,
} from './actions.js';

const initialState = {
    userList: [],
    mileageFields: {},
    journalCars: [],
    journalUsers: [],
    journalMileage: [],
};

function cvm(state = initialState, action) {
    switch(action.type) {
        case REQUEST: {
            return { ...state };
        }
        case FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case LIST_DRIVERS: {
            return {
                ...state,
                loading: false,
                userList: action.response,
            };
        }
        case EDIT_DRIVERS: {
            const userList = state.userList.map(row => action.response.find(r => r.id === row.id) || row);

            return {
                ...state,
                userList,
            };
        }
        case DELETE_DRIVER: {
            return {
                ...state,
                userList: state.userList.filter(p => p.id !== action.id),
            };
        }
        case GET_MILEAGE_FIELDS:
        case SET_MILEAGE_FIELDS: {
            const mileageFields = Object.fromEntries(
                Object.entries(action.response).map(([fieldName, v]) => [
                    fieldName,
                    v.visible_driver,
                ]),
            );

            return {
                ...state,
                mileageFields,
            };
        }
        case GET_JOURNAL_CARS: {
            return {
                ...state,
                journalCars: action.response,
            };
        }
        case GET_JOURNAL_USERS: {
            return {
                ...state,
                journalUsers: action.response,
            };
        }
        case GET_JOURNAL_MILEAGE: {
            return {
                ...state,
                journalMileage: action.response,
            };
        }
        case UPDATE_MILEAGE: {
            const newMileages = state.journalMileage.filter(mileage => mileage.id !== action.id);
            return {
                ...state,
                journalMileage: [...newMileages, action.response],
            };
        }
        case DELETE_MILEAGE: {
            return {
                ...state,
                journalMileage: state.journalMileage.filter(mileage => mileage.id !== action.id),
            };
        }
        default: { return state; }
    }
}

export default cvm;
