import moment from 'moment';
import 'moment/locale/fi';
import 'moment/locale/de';
import { t, addLocale, useLocale as doUseLocale } from 'ttag';
import { BACKEND_DATETIME_FORMAT } from 'constants.js';

export const RADIAN = Math.PI / 180;

export const TODAY = moment(); // TODO: This uses default locale, instead of user's locale

export const MONTHS = i => [
    t`JAN`,
    t`FEB`,
    t`MAR`,
    t`APR`,
    t`MAY`,
    t`JUN`,
    t`JUL`,
    t`AUG`,
    t`SEP`,
    t`OCT`,
    t`NOV`,
    t`DEC`,
][i];

export const getLocaleDateFormat = (forDatePicker = true, withTime = false) => {
    const dateFormat = moment.localeData().longDateFormat('L');
    const timeFormat = moment.localeData().longDateFormat('LT');

    // react-datepicker's <DatePicker /> -component handles DD, YYYY, and A differently than moment.
    // For the <DatePicker /> component, those tokens need to be turned into lowercase.
    // See: https://github.com/date-fns/date-fns/blob/master/docs/unicodeTokens.md

    if(forDatePicker) {
        return withTime
            ? `${dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy')} ${timeFormat.replace('A', 'a')}`
            : dateFormat.replace('DD', 'dd').replace('YYYY', 'yyyy');
    } else {
        return withTime
            ? `${dateFormat} ${timeFormat}`
            : dateFormat;
    }
};

export const fetchLocale = () => (
    localStorage.getItem('locale') || (navigator.languages && navigator.languages[0]) || navigator.language || 'fi-FI');

export const changeLanguage = locale => {
    try {
        /* eslint-disable global-require */
        const translationsObj = require(`./i18n/${locale}.po.json`);
        addLocale(locale, translationsObj);
        setLocale(locale);
    } catch (e) {
        console.warn('could not load language: ', locale);

        locale = 'fi'; // fallback to finnish locale

        const translationsObj = require(`./i18n/${locale}.po.json`);
        /* eslint-enable global-require */
        addLocale(locale, translationsObj);
        setLocale(locale);
    }
};

const setLocale = lang => {
    localStorage.setItem('locale', lang);
    lang === 'zh' ? moment.locale('zh-cn') : moment.locale(lang);
    moment.weekdays(true);
    doUseLocale(lang);
};

export const formatter = new Intl.NumberFormat(fetchLocale(), {
    style: 'currency',
    currency: 'EUR',
});

export const formatDateTime = dateTime => {
    const currentLocale = fetchLocale();

    try {
        const newDate = new Date(dateTime);
        const date = new Intl.DateTimeFormat(currentLocale, {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
        }).format(newDate);
        const time = new Intl.DateTimeFormat(currentLocale, {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
        }).format(newDate);
        const timeWithoutSeconds = new Intl.DateTimeFormat(currentLocale, {
            hour12: false,
            hour: 'numeric',
            minute: 'numeric',
        }).format(newDate);

        const full = date.concat(' ', time);

        return {
            full,
            date,
            time,
            timeWithoutSeconds,
        };
    } catch (e) {
        console.error(e);
    }
};

export const isValidBackendTime = str => moment(str, BACKEND_DATETIME_FORMAT).isValid();

export const toBase64 = file => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = () => reject(reader.error);
});

export const anyColorToHex = str => {
    const temp = document.createElement('i');
    temp.style.display = 'none';
    temp.style.color = str;
    document.body.appendChild(temp);

    const maybeColor = getComputedStyle(temp).getPropertyValue('color');
    temp.remove();
    if(maybeColor) {
        return maybeColor;
    } else {
        const varAsHex = getComputedStyle(document.documentElement).getPropertyValue(`--${str}`);

        if(varAsHex) {
            return varAsHex;
        } else {
            console.warn('Unexpected value passed to anyColorToHex()', str);
            return null;
        }
    }
};

// Returns true if the color in {hexOrRGB} string is in hex or rgb format, and dark
export const isColorDark = hexOrRGB => {
    let rgb = hexOrRGB.trim(); // getComputedValue returns strings with whitespace......................................
    let r = null;
    let g = null;
    let b = null;

    if(rgb.charAt(0) === '#') {
        rgb = parseInt(rgb.substring(1), 16);
        /* eslint-disable no-bitwise */
        r = (rgb >> 16) & 0xff;
        g = (rgb >> 8) & 0xff;
        b = (rgb >> 0) & 0xff;
        /* eslint-enable no-bitwise */
    } else {
        [r, g, b] = rgb.match(/\d+/g);
    }

    const luma = (0.2126 * r) + (0.7152 * g) + (0.0722 * b);

    return luma < 128;
};

export const formDataToJSON = fd => {
    const r = {};

    fd.forEach((v, k) => {
        if(r.hasOwnProperty(k)) { r[k] = Array.isArray(r[k]) ? r[k].push(v) : [r[k], v]; } else { r[k] = v; }
    });

    return JSON.stringify(r);
};

export const VEHICLE_CLASSES = () => [
    {
        name: 'M1',
        description: t`M1_TOOLTIP`,
        isHeavy: false,
        isLight: true,
    },
    {
        name: 'M2',
        description: t`M2_TOOLTIP`,
        isHeavy: false,
        isLight: true,
    },
    {
        name: 'M3',
        description: t`M3_TOOLTIP`,
        isHeavy: true,
        isLight: false,
    },
    {
        name: 'N1',
        description: t`N1_TOOLTIP`,
        isHeavy: false,
        isLight: true,
    },
    {
        name: 'N2',
        description: t`N2_TOOLTIP`,
        isHeavy: true,
        isLight: false,
    },
    {
        name: 'N3',
        description: t`N3_TOOLTIP`,
        isHeavy: true,
        isLight: false,
    },
    {
        name: 'O1',
        description: t`O1_TOOLTIP`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'O2',
        description: t`O2_TOOLTIP`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'O3',
        description: t`O3_TOOLTIP`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'O4',
        description: t`O4_TOOLTIP`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: null,
        description: t`OTHER`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'L1',
        description: t`L1_TOOLTIP`,
        shortDescription: t`L1_TOOLTIP_SHORT`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'L2',
        description: t`L2_TOOLTIP`,
        shortDescription: t`L2_TOOLTIP_SHORT`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'L3',
        description: t`L3_TOOLTIP`,
        shortDescription: t`L3_TOOLTIP_SHORT`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'L4',
        description: t`L4_TOOLTIP`,
        shortDescription: t`L4_TOOLTIP_SHORT`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'L5',
        description: t`L5_TOOLTIP`,
        shortDescription: t`L5_TOOLTIP_SHORT`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'L6',
        description: t`L6_TOOLTIP`,
        shortDescription: t`L6_TOOLTIP_SHORT`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'L7',
        description: t`L7_TOOLTIP`,
        shortDescription: t`L7_TOOLTIP_SHORT`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'T',
        description: t`T_TOOLTIP`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'G',
        description: t`G_TOOLTIP`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'SA',
        description: t`SA_TOOLTIP`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'SB',
        description: t`SB_TOOLTIP`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'SC',
        description: t`SC_TOOLTIP`,
        isHeavy: false,
        isLight: false,
    },
    {
        name: 'SD',
        description: t`SD_TOOLTIP`,
        isHeavy: false,
        isLight: false,
    },
];

export const b64toBlob = (b64Data, contentType, sliceSize) => {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for(let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for(let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
};

export const formRequiredValidation = (values = {}) => {
    const errors = {};

    for(const [key, value] of Object.entries(values)) {
        if(value === '') {
            errors[key] = t`REQUIRED`;
        } else {
            errors[key] = '';
        }
    }

    return errors;
};

export const downloadHttpResponseFile = (res, name) => {
    // If you get errors relating to createObjectUrl, make sure your action has the 'responseType: blob'
    const link = document.createElement('a');
    link.setAttribute('href', window.URL.createObjectURL(res));
    link.setAttribute('target', '_blank');
    name && link.setAttribute('download', name);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    link.remove();
};

export const arrayEquals = (a, b) => (
    Array.isArray(a) &&
    Array.isArray(b) &&
    a.length === b.length &&
    a.every((v, i) => v === b[i])
);

export const randInt = max => Math.round(Math.random() * max);

export const findNiceMax = rawMax => {
    const orderOfMagnitude = 10 ** Math.floor(Math.log(rawMax) / Math.LN10 + .00000000000001);

    return Math.floor((rawMax + orderOfMagnitude - 1) / orderOfMagnitude) * orderOfMagnitude;
};

export const getNiceChartMax = max => {
    max = Math.ceil(max);

    const orderOfMagnitude = 10 ** Math.floor(Math.log(max) / Math.LN10 + .00000000000001);
    const niceMax = Math.floor((max + orderOfMagnitude - 1) / orderOfMagnitude) * orderOfMagnitude;

    return niceMax || 100;
};

export const generateYAxisTicks = (max, steps = 5) => {
    const niceMax = getNiceChartMax(max);

    return Array(steps).fill().map((_, i) => i * (niceMax / (steps - 1)));
};

export const numericSort = (a, b, key) => {
    let comparedA = a[key];
    let comparedB = b[key];
    const noValue = [null, undefined];

    if(noValue.includes(comparedA) && !noValue.includes(comparedB)) {
        comparedB = 1;
    } else if(!noValue.includes(comparedA) && noValue.includes(comparedB)) {
        comparedA = 1;
    } else if(noValue.includes(comparedB) && noValue.includes(comparedA)) return 0;

    return (comparedA - comparedB > 0 ? 1 : -1);
};

export const alphabeticSortNull = (a, b, key, order) => {
    // Compares all alphabetical values, if value is null it's set on the bottom
    // If you want to sort the nulls on top, move "* order" to outside of this function
    const comparedA = a[key];
    const comparedB = b[key];
    const noValue = [null, undefined];

    if(noValue.includes(comparedA) && !noValue.includes(comparedB)) return 1;
    else if(noValue.includes(comparedB) && !noValue.includes(comparedA)) return -1;
    else if(noValue.includes(comparedB) && noValue.includes(comparedA)) return 0;

    return comparedA.localeCompare(comparedB) * order;
};

export const getDataMax = (arr, key) => arr
    .map(d => d.data)
    .flat()
    .reduce((acc, cur) => Math.max(acc, cur[key]), 0);

export const roundNumeric = val => (((val === null) || (val === undefined)) ? null : +val.toFixed(1));

export const formatGrams = value => {
    if((value === null) || (value === undefined) || Number.isNaN(value)) return [null, null];

    if(value > 1000000) return [roundNumeric(value / 1000000), 't'];
    if(value > 1000) return [roundNumeric(value / 1000), 'kg'];
    return [roundNumeric(value), 'g'];
};
