import { ReactComponent as IconVehicles } from 'static/Navigation/icon-vehicles.svg';
import './icon-fleets.scss';

export default ({ className }) => (
    <div className={`icon-fleets ${className || ''}`}>
        <IconVehicles />
        <IconVehicles />
        <IconVehicles />
    </div>
);
