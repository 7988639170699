import { changeLanguage } from 'utils';

export const SET_LOADING = 'SET_LOADING';
export const SET_CURRENT_ROUTE = 'SET_CURRENT_ROUTE';
export const SHOW_TOP_NOTIFICATION = 'SHOW_TOP_NOTIFICATION';
export const SET_TOP_NOTIFICATION_STYLE = 'SET_TOP_NOTIFICATION_STYLE';
export const HIDE_TOP_NOTIFICATION = 'HIDE_TOP_NOTIFICATION';
export const TOGGLE_MENU = 'TOGGLE_MENU';
export const HIDE_NOTIFICATION_MODAL = 'HIDE_NOTIFICATION';
export const SHOW_NOTIFICATION_MODAL = 'SHOW_NOTIFICATION';
export const CHANGE_LOCALE = 'CHANGE_LOCALE';
export const UPDATE_SW = 'UPDATE_SW';
export const DECIDE_COOKIES = 'DECIDE_COOKIES';

export function setLoading(on) {
    return {
        type: SET_LOADING,
        on,
    };
}

export function hideNotification() {
    return {
        type: HIDE_TOP_NOTIFICATION,
    };
}

export function toggleMenu() {
    return { type: TOGGLE_MENU };
}

export function hideNotificationModal() {
    return { type: HIDE_NOTIFICATION_MODAL };
}

export function showNotificationModal(title, body, options) {
    return {
        type: SHOW_NOTIFICATION_MODAL,
        title,
        body,
        options,
    };
}

export function changeLocale(langCode) {
    changeLanguage(langCode);

    return {
        type: CHANGE_LOCALE,
        lang: langCode,
    };
}

export function update() {
    return { type: UPDATE_SW };
}

export function decideCookies(decision) { return { type: DECIDE_COOKIES, decision }; }

export function showTopNotification({ text, type, style }) {
    return { type: SHOW_TOP_NOTIFICATION, text, notificationType: type, style };
}

export function setTopNotificationStyle(style) {
    return { type: SET_TOP_NOTIFICATION_STYLE, style };
}
