import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const SAVE_USER = 'SAVE_USER';
export const GET_USER = 'GET_USER';
export const SAVE_PROFILE = 'SAVE_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';
export const GET_COUNTRIES = 'GET_COUNTRIES';
export const GET_EVENTS = 'GET_EVENTS';
export const UPLOAD_ICON = 'UPLOAD_ICON';
export const GET_ICON = 'GET_ICON';
export const GET_TIER = 'GET_TIER';
export const UPGRADE_TIER = 'UPGRADE_TIER';
export const ACTIVATE_USER = 'ACTIVATE_USER';
export const SET_NOTIFIED_ABOUT_TIER_LIMIT = 'SET_NOTIFIED_ABOUT_TIER_LIMIT';

export function saveUser(userData) {
    return {
        [CALL_API]: {
            endpoint: API.user.base,
            method: 'PUT',
            types: [REQUEST, SAVE_USER, FAILURE],
            body: userData,
        },
    };
}

export function saveProfile(profileData) {
    return {
        [CALL_API]: {
            endpoint: API.user.profile,
            method: 'PUT',
            types: [REQUEST, SAVE_PROFILE, FAILURE],
            body: profileData,
        },
    };
}

export function getUser() {
    return {
        [CALL_API]: {
            endpoint: API.user.base,
            method: 'GET',
            types: [REQUEST, GET_USER, FAILURE],
        },
    };
}

export function getProfile() {
    return {
        [CALL_API]: {
            endpoint: API.user.profile,
            method: 'GET',
            types: [REQUEST, GET_PROFILE, FAILURE],
            showLoading: false,
        },
    };
}

// TODO: move this to be used for global nav bar
export function getIcon() {
    return {
        [CALL_API]: {
            endpoint: API.user.icon,
            method: 'GET',
            types: [REQUEST, GET_ICON, FAILURE],
        },
    };
}

export function uploadIcon(icon) {
    return {
        [CALL_API]: {
            endpoint: API.user.icon,
            method: 'PUT',
            types: [REQUEST, UPLOAD_ICON, FAILURE],
            body: { icon_file: icon },
        },
    };
}

export function getCountries() {
    return {
        [CALL_API]: {
            endpoint: API.countries,
            method: 'GET',
            types: [REQUEST, GET_COUNTRIES, FAILURE],
        },
    };
}

export function getEvents() {
    return {
        [CALL_API]: {
            endpoint: API.events,
            method: 'GET',
            types: [REQUEST, GET_EVENTS, FAILURE],
        },
    };
}

export function getUserTierInfo() {
    return {
        [CALL_API]: {
            endpoint: API.user.tier,
            method: 'GET',
            types: [REQUEST, GET_TIER, FAILURE],
            showLoading: false,
        },
    };
}

export function setNotifiedAboutBeingOverTierLimit() {
    return { type: SET_NOTIFIED_ABOUT_TIER_LIMIT };
}

export function upgradeUserTier() {
    return {
        [CALL_API]: {
            endpoint: API.user.tier,
            method: 'PUT',
            types: [REQUEST, UPGRADE_TIER, FAILURE],
        },
    };
}

export function activateUser(token) {
    return {
        [CALL_API]: {
            endpoint: API.user.activate.replace(':token', token),
            method: 'PATCH',
            types: [REQUEST, ACTIVATE_USER, FAILURE],
        },
    };
}
