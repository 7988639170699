import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';
import { getParsedLSItemOr } from 'components/Auth/reducers.js';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_CAR_DATA = 'GET_CAR_DATA';
export const GET_CAR_DATA_FAILURE = 'GET_CAR_DATA_FAILURE';
export const GET_MMTY = 'GET_MMTY';
export const UPLOAD_CSV = 'UPLOAD_CSV';

export function getCarData(data) {
    return {
        [CALL_API]: {
            endpoint: API.vehicles.data,
            method: 'POST',
            showError: false,
            showSuccess: false,
            types: [REQUEST, GET_CAR_DATA, GET_CAR_DATA_FAILURE],
            body: data,
        },
    };
}

export function getMakes({ endpointPrefix = '', captchaToken, clean }) {
    endpointPrefix = endpointPrefix || (getParsedLSItemOr('is_fweewawe') ? 'freeware/' : endpointPrefix);
    clean = clean ? '1/' : '';

    return {
        [CALL_API]: {
            endpoint: API.vehicles.make
                .replace(':prefix/', endpointPrefix)
                .replace(':clean/', clean),
            method: 'GET',
            types: [REQUEST, GET_MMTY, FAILURE],
            ...(captchaToken && { headers: { 'Recaptcha-token': captchaToken } }),
        },
    };
}

export function getMakesDefault({ captchaToken }) {

    return {
        [CALL_API]: {
            endpoint: API.vehicles.default,
            method: 'GET',
            types: [REQUEST, GET_MMTY, FAILURE],
            ...(captchaToken && { headers: { 'Recaptcha-token': captchaToken } }),
        },
    };
}

export function getModelTypes({ endpointPrefix = '', make, year, captchaToken, clean }) {
    endpointPrefix = endpointPrefix || (getParsedLSItemOr('is_fweewawe') ? 'freeware/' : endpointPrefix);
    year = year ? `${year}/` : '';
    clean = clean ? '1/' : '';

    return {
        [CALL_API]: {
            endpoint: API.vehicles.model_type
                .replace(':prefix/', endpointPrefix)
                .replace(':clean/', clean)
                .replace(':make', make)
                .replace(':year/', year),
            method: 'GET',
            types: [REQUEST, GET_MMTY, FAILURE],
            ...(captchaToken && { headers: { 'Recaptcha-token': captchaToken } }),
        },
    };
}

export function getYears({ endpointPrefix = '', make, MT, captchaToken, clean }) {
    endpointPrefix = endpointPrefix || (getParsedLSItemOr('is_fweewawe') ? 'freeware/' : endpointPrefix);
    clean = clean ? '1/' : '';
    MT = MT ? `${MT}/` : '';

    return {
        [CALL_API]: {
            endpoint: API.vehicles.year
                .replace(':prefix/', endpointPrefix)
                .replace(':clean/', clean)
                .replace(':make', make)
                .replace(':model_type/', MT),
            method: 'GET',
            types: [REQUEST, GET_MMTY, FAILURE],
            ...(captchaToken && { headers: { 'Recaptcha-token': captchaToken } }),
        },
    };
}

export function getMMTYKey({ endpointPrefix = '', make, MT, year, captchaToken, clean }) {
    endpointPrefix = endpointPrefix || (getParsedLSItemOr('is_fweewawe') ? 'freeware/' : endpointPrefix);
    clean = clean ? '1/' : '';

    return {
        [CALL_API]: {
            endpoint: API.vehicles.mmty_key
                .replace(':prefix/', endpointPrefix)
                .replace(':clean/', clean)
                .replace(':make', make)
                .replace(':model_type', MT)
                .replace(':year', year),
            method: 'GET',
            types: [REQUEST, GET_MMTY, FAILURE],
            ...(captchaToken && { headers: { 'Recaptcha-token': captchaToken } }),
        },
    };
}

export function uploadCSV(file) {
    const body = new FormData();
    body.append('csv_file', file);

    return {
        [CALL_API]: {
            endpoint: API.vehicles.CSV,
            method: 'POST',
            types: [REQUEST, UPLOAD_CSV, FAILURE],
            body,
        },
    };
}
