import {
    REQUEST,
    FAILURE,
    GET_VIDEOS,
    GET_FAQ,
} from './actions.js';

const initialState = {
    videos: [],
    faq: [],
};

function help(state = initialState, action) {
    switch(action.type) {
        case REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case GET_VIDEOS: {
            return {
                ...state,
                videos: action.response,
            };
        }
        case GET_FAQ: {
            return {
                ...state,
                faq: action.response,
            };
        }
        default: { return state; }
    }
}

export default help;
