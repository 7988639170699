import { useSelector } from 'react-redux';
import { ClipLoader } from 'react-spinners';
import './index.scss';

/**
 * This loading component is shown during API requests
 */
const DataLoading = () => {
    const loading = useSelector(state => state.Layout.loading);

    console.assert(loading > -1, 'Loading action count is < 0. Shouldn\'t be happening.', { loading });

    return loading > 0 ? (
        <div className='data-loading-wrapper'>
            <ClipLoader sizeUnit='px' size={65} color='var(--fill-color-blue);' />
        </div>
    ) : null;
};

export default DataLoading;
