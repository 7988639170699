import * as Sentry from '@sentry/browser';
import { t } from 'ttag';

export const initializeSentry = () => {
    if(process.env.NODE_ENV !== 'development') {
        Sentry.init({
            dsn: 'https://ca35f85b07334055a166d10050e591f8@sentry.vedia.fi/7',
            environment: process.env.NODE_ENV,
            ignoreErrors: [
                'ResizeObserver loop limit exceeded',
                'ResizeObserver loop completed with undelivered notifications',
            ],
            beforeSend(event) {
                if(event.exception) {
                    Sentry.showReportDialog({
                        eventId: event.event_id,
                        title: t`It looks like we’re having issues.`,
                        subtitle: t`Our team has been notified.`,
                        subtitle2: t`If you’d like to help, tell us what happened below.`,
                        labelName: t`Name`,
                        labelEmail: t`Email`,
                        labelComments: t`What happened?`,
                        labelClose: t`Close`,
                        labelSubmit: t`Submit`,
                        errorGeneric: t`An unknown error occurred while submitting your report. Please try again.`,
                        errorFormEntry: t`Some fields were invalid. Please correct the errors and try again.`,
                        successMessage: t`Your feedback has been sent. Thank you!`,
                    });
                }
                return event;
            },
        });

        addUser(JSON.parse(localStorage.getItem('user')));
    }
};


export const addUser = user => {
    if(process.env.NODE_ENV !== 'development') {
        if(user && user.email) {
            Sentry.configureScope(scope => {
                scope.setUser({
                    email: user.email,
                });
            });
        }
    }
};
