import { useEffect } from 'react';
import { t } from 'ttag';
import { connect, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { ReactComponent as IconOffer } from 'static/Navigation/icon-offer.svg';
import { ReactComponent as Logo } from 'static/Common/logo.svg';
import { ReactComponent as IconDashboard } from 'static/Navigation/icon-dashboard.svg';
import { ReactComponent as IconVehicles } from 'static/Navigation/icon-vehicles.svg';
import { ReactComponent as IconLogout } from 'static/Navigation/logout.svg';
import { ReactComponent as IconHelp } from 'static/Navigation/icon-tooltip.svg';
import { ReactComponent as IconMobile } from 'static/Navigation/icon-mobile.svg';
import { ReactComponent as IconProfile } from 'static/Navigation/icon-profile.svg';
import { ReactComponent as IconMileageReport } from 'static/Navigation/icon-report-mileage.svg';
import IconFleets from 'static/Navigation/icon-fleets.js';
import { showNotificationModal } from 'components/layouts/actions.js';
import { getProfile } from 'components/Account/actions.js';
import './index.scss';

// These are used for highlighting the correct navbar items
export const menuItems = {
    Dashboard: 'DASHBOARD',
    Vehicles: 'VEHICLES',
    Fleets: 'FLEETS',
    Booking: 'BOOKING',
    Mileage: 'MILEAGE',
    CVM: 'CVM',
    Account: 'ACCOUNT',
    Settings: 'SETTINGS',
    Help: 'HELP',
};

const IconAccount = ({ icon, name }) => (
    <div className='profile-picture'>
        {icon
            ? <img width='32' height='32' src={`data:image/jpeg;base64, ${icon}`} alt='' />
            : <h4>{name?.[0]?.toUpperCase() || <IconProfile />}</h4>}
    </div>
);

const NavItem = ({ item, isLogo, isFwee }) => {
    const dispatch = useDispatch();
    const { active, path, Icon, label, id, hash, className, notificationTitle, notificationBody } = item;
    const itemClassName = isLogo ? 'logo' : `nav-item ${active ? 'active ' : ''}`;

    return (isFwee && notificationTitle) ? (
        <button
            type='button'
            className={`${itemClassName} ${className || ''}`}
            onClick={() => dispatch(showNotificationModal(notificationTitle, notificationBody))}
            data-tooltip={id === 'Logo' ? undefined : label}
            aria-label={id === 'Logo' ? t`CVW` : label}
        >
            <Icon />
        </button>
    ) : (
        <Link
            to={{ pathname: path, state: label || undefined, hash }}
            className={`${itemClassName} ${className || ''}`}
            data-tooltip={id === 'Logo' ? undefined : label}
            aria-label={id === 'Logo' ? t`CVW` : label}
        >
            <Icon />
        </Link>
    );
};

const navTitles = (active, profile, { masterUser }) => ({
    Logo: { path: '/', Icon: Logo, active: false },
    Dashboard: { label: t`DASHBOARD`, path: '/', Icon: IconDashboard, active: active === menuItems.Dashboard },
    Vehicles: { label: t`VEHICLES`, path: '/vehicles', Icon: IconVehicles, active: active === menuItems.Vehicles },
    Fleets: { label: t`FLEETS`, path: '/fleets', Icon: IconFleets, active: active === menuItems.Fleets },
    Booking: { label: t`BOOKING`, path: '/booking', Icon: IconOffer, active: active === menuItems.Booking },
    Mileage: {
        label: t`REPORTS`,
        path: '/reports',
        Icon: IconMileageReport,
        active: active === menuItems.Mileage,
        notificationTitle: t`FWEEWAWE_MILEAGE_TITLE`,
        notificationBody: t`FWEEWAWE_MILEAGE_BODY`,
    },
    ...(masterUser && { CVM: { label: t`CVM`, path: '/cvm/new', Icon: IconMobile, active: active === menuItems.CVM } }),
    Account: {
        label: t`ACCOUNT`,
        path: '/account/profile',
        hash: '#profile',
        Icon: () => <IconAccount icon={profile.icon} name={profile.name} />,
        active: active === menuItems.Account,
    },
    HelpCenter: { label: t`HELP`, path: '/help', Icon: IconHelp, active: active === menuItems.Help },
    Logout: { label: t`LOGOUT`, path: '/logout', Icon: IconLogout, active: false, className: 'tooltip-top' },
});

const GlobalNavigationBar = ({ activeTab, getProfile, groupProfile, isFwee, Auth }) => {
    useEffect(() => { groupProfile.name || getProfile(); }, []);

    const items = navTitles(activeTab, groupProfile, Auth);

    return (
        <nav className='global-navi-wrapper'>
            {Object.keys(items).map((item, i) => (
                <NavItem key={i} item={items[item]} isLogo={item === 'Logo'} isFwee={isFwee} />
            ))}
        </nav>
    );
};

export default connect(state => ({
    groupProfile: state.Account.groupProfile,
    isFwee: state.Auth.isFwee,
    Auth: state.Auth,
}), { getProfile })(GlobalNavigationBar);
