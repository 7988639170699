import { fetchLocale } from 'utils.js';
import {
    SET_LOADING,
    SHOW_TOP_NOTIFICATION,
    SET_TOP_NOTIFICATION_STYLE,
    HIDE_TOP_NOTIFICATION,
    TOGGLE_MENU,
    SHOW_NOTIFICATION_MODAL,
    HIDE_NOTIFICATION_MODAL,
    UPDATE_SW,
    DECIDE_COOKIES,
    CHANGE_LOCALE,
} from './actions.js';

const getCookieDecisionFromLocalStorage = (() => {
    const ls = localStorage.getItem('cookies');
    let decision = null;

    if(ls !== null) {
        try {
            decision = JSON.parse(ls);
        } catch {
            console.error('Invalid JSON in localStorage.getItem(\'cookies\')', ls);
        }
    }

    return decision;
})();

const initialState = {
    cookies: getCookieDecisionFromLocalStorage,
    lang: fetchLocale(),
    loading: 0,
    topNotification: {
        visible: false,
        text: null,
        type: null,
        style: null,
    },
    showMenu: true,
    notificationModal: {
        open: false,
        title: '',
        body: '',
        options: {},
        update: false,
    },
};

let resetTimeout;

export default function layout(state = initialState, action) {
    switch(action.type) {
        case SET_LOADING: {
            const { loading } = state;

            return { ...state, loading: action.on ? loading + 1 : loading - 1 };
        }
        case SHOW_TOP_NOTIFICATION: {
            const { text, notificationType, style } = action;
            const { topNotification } = state;

            resetTimeout && clearTimeout(resetTimeout);
            resetTimeout = setTimeout(() => action.asyncDispatch({ type: 'HIDE_TOP_NOTIFICATION' }), 4000);

            return {
                ...state,
                topNotification: {
                    text: text ?? topNotification.text,
                    type: (notificationType ?? topNotification.type) || 'error',
                    style: style ?? topNotification.style,
                    visible: true,
                },
            };
        }
        case SET_TOP_NOTIFICATION_STYLE: {
            return {
                ...state,
                topNotification: { ...state.topNotification, style: action.style },
            };
        }
        case HIDE_TOP_NOTIFICATION: {
            resetTimeout && clearTimeout(resetTimeout);
            return {
                ...state,
                topNotification: { ...state.topNotification, visible: false },
            };
        }
        case TOGGLE_MENU: {
            return {
                ...state,
                showMenu: !state.showMenu,
            };
        }
        case SHOW_NOTIFICATION_MODAL: {
            const { title, body, options } = action;

            return {
                ...state,
                notificationModal: {
                    open: true,
                    title,
                    body,
                    options,
                    update: state.notificationModal.update,
                },
            };
        }
        case HIDE_NOTIFICATION_MODAL: {
            return {
                ...state,
                notificationModal: {
                    ...state.notificationModal,
                    open: false,
                },
            };
        }
        // added here, thought no css, but yes css :D
        case UPDATE_SW: {
            return {
                ...state,
                notificationModal: {
                    ...state.notificationModal,
                    update: true,
                },
            };
        }
        case DECIDE_COOKIES: {
            const { decision } = action;

            localStorage.setItem('cookies', !!decision);

            if(decision) {
                // Do nothing, because we don't have google analytics anymore, lmao
            }

            return { ...state, cookies: decision };
        }
        case CHANGE_LOCALE: {
            return { ...state, lang: action.lang };
        }
        default: { return state; }
    }
}
