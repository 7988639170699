import { lazy, Suspense, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Routes, Route, Navigate } from 'react-router-dom';
import { t } from 'ttag';
import { menuItems } from 'components/layouts/GlobalNavigationBar';
import CookieDisclaimer from 'components/layouts/CookieDisclaimer/index.js';
import RouteLoading from 'components/layouts/LoadingViews/RouteLoading';
import DataLoading from 'components/layouts/LoadingViews/DataLoading';
import 'styles/base.scss';
import 'styles/print.scss';

const CoreLayout = lazy(() => import('components/layouts/CoreLayout'));
const NotFound = lazy(() => import('components/layouts/NotFound'));

const DashboardView = lazy(() => import('components/Dashboard'));

const VehiclesView = lazy(() => import('components/Fleets/VehiclesView/index.js'));
const FleetsView = lazy(() => import('components/Fleets/FleetsView/index.js'));
const FleetVehicleViewRenderer = lazy(() => import('components/Fleets/FleetVehicleViewRenderer.js'));

const ChooseReport = lazy(() => import('components/Reports/index.js'));
const ReportRenderer = lazy(() => import('components/Reports/ReportRenderer.js'));

const Booking = lazy(() => import('components/Booking/index.js'));

const CVMLayout = lazy(() => import('components/CVM/index.js'));

const CVMNew = lazy(() => import('components/CVM/New/index.js'));
const CVMEdit = lazy(() => import('components/CVM/Edit/index.js'));
const CVMMileage = lazy(() => import('components/CVM/Mileage/index.js'));
const Journal = lazy(() => import('components/CVM/Journal/index.js'));
// const CVMConfiguration = lazy(() => import('components/CVM/Configuration/index.js'));

const AccountView = lazy(() => import('components/Account'));
const Activate = lazy(() => import('components/Account/Activate.js'));

const HelpCenter = lazy(() => import('components/HelpCenter/index.js'));
const FAQ = lazy(() => import('components/HelpCenter/FAQ/index.js'));
const ContactUs = lazy(() => import('components/HelpCenter/ContactUs/index.js'));

const Login = lazy(() => import('components/Auth/Login'));
const Logout = lazy(() => import('components/Auth/Logout'));
const ResetPassword = lazy(() => import('components/Auth/Reset/index.js'));
const SetPassword = lazy(() => import('components/Auth/SetPassword/index.js'));

const { Dashboard, Vehicles, Fleets, Mileage, Help, CVM, Account } = menuItems;

const RouteSwitch = () => {
    const lang = useSelector(state => state.Layout.lang); // Defined here so whole app rerenders on lang change
    const cookies = useSelector(state => state.Layout.cookies);
    const isFwee = useSelector(state => state.Auth.isFwee);
    const isOrganization = useSelector(state => state.Auth.isOrganization);
    const masterUser = useSelector(state => state.Auth.masterUser);
    const isAuthenticated = useSelector(state => state.Auth.isAuthenticated);

    const notPrivate = true;

    const routes = useMemo(() => ({
        '/': { element: <DashboardView />, title: t`DASHBOARD`, menu: Dashboard, isIndex: true },
        '/vehicles': { element: <VehiclesView />, title: t`VEHICLES`, menu: Vehicles },
        '/fleets': { element: <FleetsView />, title: t`FLEETS`, menu: Fleets },
        '/fleets/:id': { element: <FleetsView />, title: t`FLEETS`, menu: Fleets },
        '/fleets/:id/vehicles': { element: <FleetVehicleViewRenderer />, title: t`FLEET_DETAILS`, menu: Fleets },
        ...(!isFwee && {
            '/reports': { element: <ChooseReport />, title: t`CHOOSE_REPORT`, menu: Mileage },
            '/reports/:view': { element: <ReportRenderer />, title: t`REPORT`, menu: Mileage },
        }),
        '/help': { element: <HelpCenter />, title: t`HELP_CENTER`, menu: Help },
        '/booking': { element: <Booking />, title: t`BOOKING`, menu: Booking },
        '/faq': { element: <FAQ />, title: t`FAQ`, menu: Help },
        '/contact-us': { element: <ContactUs />, title: t`CONTACT_US`, menu: Help },
        ...(masterUser && {
            '/cvm': { element: <CVMNew />, title: t`CVM_MANAGEMENT`, menu: CVM, Layout: CVMLayout },
            '/cvm/new': { element: <CVMNew />, title: t`CVM_MANAGEMENT`, menu: CVM, Layout: CVMLayout },
            '/cvm/edit': { element: <CVMEdit />, title: t`CVM_MANAGEMENT`, menu: CVM, Layout: CVMLayout },
            '/cvm/custom/mileage': { element: <CVMMileage />, title: t`CVM_MANAGEMENT`, menu: CVM, Layout: CVMLayout },
            'cvm/journal': { element: <Journal />, title: t`CVM_JOURNAL`, menu: CVM, Layout: CVMLayout },
            /* '/cvm/custom/configuration': {
                element: <CVMConfiguration />,
                title: t`CVM_MANAGEMENT`,
                menu: CVM,
                Layout: CVMLayout,
            }, */
        }),
        '/account/:view': { element: <AccountView />, title: t`ACCOUNT`, menu: Account },
        '/activate/:token': { element: <Activate />, title: t`ACTIVATE_USER`, notPrivate },
        '/reset': { element: <ResetPassword />, title: t`RESET_PASSWORD`, notPrivate },
        '/reset/:token': { element: <SetPassword />, title: t`SET_NEW_PASSWORD`, notPrivate },
        '/login': { element: <Login />, title: t`LOGIN`, notPrivate },
        '/logout': { element: <Logout />, title: t`LOGOUT` },
        '*': { element: <NotFound />, title: t`NOT_FOUND` },
    }), [isFwee, isOrganization, masterUser, lang]);

    return (
        <>
            {(cookies === null) && <CookieDisclaimer />}
            <DataLoading />
            <Suspense fallback={<RouteLoading />}>
                <Routes>
                    {Object.keys(routes).map(path => {
                        const route = routes[path];
                        const { isIndex, notPrivate, Layout, title, menu } = route;
                        let { element } = route;

                        if(!notPrivate && !isAuthenticated) element = <Navigate to='/login' />;
                        if(Layout) element = <Layout>{element}</Layout>;

                        return (
                            <Route
                                key={path}
                                path={path}
                                index={isIndex}
                                element={<CoreLayout title={title} menu={menu}>{element}</CoreLayout>}
                            />
                        );
                    })}
                </Routes>
            </Suspense>
        </>
    );
};

export default RouteSwitch;
