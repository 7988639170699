import { API } from 'constants.js';
import { CALL_API } from 'middleware/api.js';

import { getParsedLSItemOr } from 'components/Auth/reducers.js';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_FUEL_TYPES = 'GET_FUEL_TYPES';
export const SET_TARGET_FUELS = 'SET_TARGET_FUELS';
export const GET_DASHBOARD_FLEETS = 'GET_DASHBOARD_FLEETS';
export const SET_DASHBOARD_SEARCH = 'SET_DASHBOARD_SEARCH';
export const GET_TARGET_EMISSIONS = 'GET_TARGET_EMISSIONS';
export const SET_TARGET_EMISSIONS = 'SET_TARGET_EMISSIONS';
export const SET_VEHICLE_CLASS_SORT = 'SET_VEHICLE_CLASS_SORT';
export const SET_DASHBOARD_COMPARISON = 'SET_DASHBOARD_COMPARISON';
export const GET_DASHBOARD_DATA_SUCCESS = 'GET_DASHBOARD_DATA_SUCCESS';
export const GET_DASHBOARD_DATA_FAILURE = 'GET_DASHBOARD_DATA_FAILURE';
export const GET_DASHBOARD_NOTIFICATIONS = 'GET_DASHBOARD_NOTIFICATIONS';
export const READ_DASHBOARD_NOTIFICATION = 'READ_DASHBOARD_NOTIFICATION';
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const GET_POPUP_NOTIFICATION = 'GET_POPUP_NOTIFICATION';
export const GET_CSV_ERROR = 'GET_CSV_ERROR';
export const GET_DASHBOARD_REFUEL_DATA = 'GET_DASHBOARD_REFUEL_DATA';

export function setDashboardComparison(compared, sort) {
    return {
        type: SET_DASHBOARD_COMPARISON,
        compared,
        sort,
    };
}

export function setDashboardSearch(text) {
    return {
        type: SET_DASHBOARD_SEARCH,
        text,
    };
}

export function getDashboardData() {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: isFwee ? API.fweewawe.dashboard.base : API.dashboard.base,
            types: [REQUEST, GET_DASHBOARD_DATA_SUCCESS, GET_DASHBOARD_DATA_FAILURE],
            showLoading: false,
        },
    };
}

export function getDashboardNotifications() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.dashboard.notifications,
            types: [REQUEST, GET_DASHBOARD_NOTIFICATIONS, FAILURE],
            showLoading: false,
        },
    };
}

export function dismissNotification(id) {
    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: API.dashboard.notification.replace(':id', id),
            body: { dismiss: true },
            types: [REQUEST, READ_DASHBOARD_NOTIFICATION, FAILURE],
        },
    };
}

export function getTargetEmissions() {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: isFwee ? API.fweewawe.dashboard.emissions : API.dashboard.emissions,
            types: [REQUEST, GET_TARGET_EMISSIONS, FAILURE],
        },
    };
}

export function setTargetEmissions(target_emissions) {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: isFwee ? API.fweewawe.dashboard.emissions : API.dashboard.emissions,
            types: [REQUEST, SET_TARGET_EMISSIONS, FAILURE],
            body: { target_emissions },
        },
    };
}

export function setAcceptableFuelTypes(target_fuel_types) {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            method: 'PATCH',
            endpoint: isFwee ? API.fweewawe.dashboard.emissions : API.dashboard.emissions,
            types: [REQUEST, SET_TARGET_FUELS, FAILURE],
            body: { target_fuel_types },
            showSuccess: false,
        },
    };
}

export function setVehicleClassSort(newSort) {
    return {
        type: SET_VEHICLE_CLASS_SORT,
        newSort,
    };
}

export function getFuelTypes() {
    return {
        [CALL_API]: {
            endpoint: API.dashboard.fuel_types,
            method: 'GET',
            types: [REQUEST, GET_FUEL_TYPES, FAILURE],
            showLoading: false,
        },
    };
}

export function getPopupNotification() {
    return {
        [CALL_API]: {
            endpoint: API.dashboard.notifications_popup,
            method: 'GET',
            types: [REQUEST, GET_POPUP_NOTIFICATION, FAILURE],
            showLoading: false,
        },
    };
}

export function dismissPopupNotifications(ids) {
    const body = ids.map(id => ({ id, popup: false }));
    return {
        [CALL_API]: {
            endpoint: API.dashboard.notifications_popup,
            method: 'PATCH',
            types: [REQUEST, UPDATE_NOTIFICATIONS, FAILURE],
            body,
            showSuccess: false,
        },
    };
}

export function getCSVError(id) {
    return {
        [CALL_API]: {
            endpoint: API.dashboard.error_notification.replace(':id', id),
            method: 'GET',
            responseType: 'blob',
            types: [REQUEST, GET_CSV_ERROR, FAILURE],
        },
    };
}

export function getDashboardRefuelData() {
    return {
        [CALL_API]: {
            endpoint: API.dashboard.refuel,
            method: 'POST',
            types: [REQUEST, GET_DASHBOARD_REFUEL_DATA, FAILURE],
            showSuccess: false,
            showLoading: false,
        },
    };
}
