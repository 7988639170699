import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_REPORT_TEMPLATES = 'GET_REPORT_TEMPLATES';
export const DELETE_REPORT_TEMPLATES = 'DELETE_REPORT_TEMPLATES';
export const GET_EVENTS = 'GET_EVENTS';
export const GET_MILEAGE_CARS = 'GET_MILEAGE_CARS';
export const GET_MILEAGE_FLEETS = 'GET_MILEAGE_FLEETS';
export const SET_MILEAGE_VEHICLES_COLUMNS = 'SET_MILEAGE_VEHICLES_COLUMNS';
export const SET_MILEAGE_VEHICLE_SORT = 'SET_MILEAGE_VEHICLE_SORT';
export const SET_MILEAGE_VEHICLES_SEARCH = 'SET_MILEAGE_VEHICLES_SEARCH';
export const TOGGLE_MILEAGE_VEHICLE_COLUMN = 'TOGGLE_MILEAGE_VEHICLE_COLUMN';
export const SET_MILEAGES = 'SET_MILEAGES';
export const GET_REPORT_TEMPLATE = 'GET_REPORT_TEMPLATE';
export const CREATE_REPORT_TEMPLATE = 'CREATE_REPORT_TEMPLATE';
export const UPLOAD_MILEAGE_CSV = 'UPLOAD_MILEAGE_CSV';
export const UPLOAD_REFUEL_CSV = 'UPLOAD_REFUEL_CSV';
export const EXPORT_MILEAGE_PDF = 'EXPORT_MILEAGE_PDF';
export const EXPORT_MILEAGE_CSV = 'EXPORT_MILEAGE_CSV';
export const EXPORT_DRIVING_JOURNAL_CSV = 'EXPORT_DRIVING_JOURNAL_CSV';
export const GET_REPORT = 'GET_REPORT';
export const GENERATE_MILEAGE_REPORT = 'GENERATE_MILEAGE_REPORT';
export const GENERATE_VEHICLE_REPORT = 'GENERATE_VEHICLE_REPORT';
export const GENERATE_VEHICLE_EMISSIONS_REPORT = 'GENERATE_VEHICLE_EMISSIONS_REPORT';
export const GENERATE_FLEET_GENERAL_REPORT = 'GENERATE_FLEET_GENERAL_REPORT';
export const GENERATE_SELECTED_VEHICLES_REPORT = 'GENERATE_SELECTED_VEHICLES_REPORT';
export const GENERATE_SINGLE_FLEET_REPORT = 'GENERATE_SINGLE_FLEET_REPORT';
export const GENERATE_GENERAL_MILEAGE_EMISSION_REPORT = 'GENERATE_GENERAL_MILEAGE_EMISSION_REPORT';
export const GENERATE_CHOSEN_VEHICLE_MILEAGE_REPORT = 'GENERATE_CHOSEN_VEHICLE_MILEAGE_REPORT';
export const GENERATE_CVD_REPORT = 'GENERATE_CVD_REPORT';
export const GET_MILEAGE_CSV_TEMPLATE = 'GET_MILEAGE_CSV_TEMPLATE';
export const GET_MILEAGE_INITIAL_DATA = 'GET_MILEAGE_INITIAL_DATA';
export const GET_REFUEL_CSV_TEMPLATE = 'GET_REFUEL_CSV_TEMPLATE';
export const GET_WORKING_MACHINE_REPORT = 'GET_WORKING_MACHINE_REPORT';
export const GET_REPORT_CSV = 'GET_REPORT_CSV';
export const GET_REPORT_XLS = 'GET_REPORT_XLS';

export function getReportTemplates() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.mileage.report.templates,
            types: [REQUEST, GET_REPORT_TEMPLATES, FAILURE],
        },
    };
}

export function getEvents() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.mileage.events,
            types: [REQUEST, GET_EVENTS, FAILURE],
        },
    };
}

export function getCars() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.mileage.initial_cars,
            types: [REQUEST, GET_MILEAGE_CARS, FAILURE],
        },
    };
}

export function getFleets() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.mileage.initial_fleets,
            types: [REQUEST, GET_MILEAGE_FLEETS, FAILURE],
        },
    };
}

export function setVehiclesColumns(columns) {
    return {
        type: SET_MILEAGE_VEHICLES_COLUMNS,
        columns,
    };
}

export function setVehiclesSort(sort, i, sortBy) {
    return {
        type: SET_MILEAGE_VEHICLE_SORT,
        sort,
        i,
        sortBy,
    };
}

export function toggleVehicleColumn(field) {
    return {
        type: TOGGLE_MILEAGE_VEHICLE_COLUMN,
        field,
    };
}

export function setVehiclesSearch(searchText) {
    return {
        type: SET_MILEAGE_VEHICLES_SEARCH,
        searchText,
    };
}

export function setMileages(body) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.mileage.list,
            types: [REQUEST, SET_MILEAGES, FAILURE],
            body,
        },
    };
}

export function getReport(body) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.mileage.report.base,
            types: [REQUEST, GET_REPORT, FAILURE],
            body,
        },
    };
}

export function deleteReportTemplates(body) {
    return {
        [CALL_API]: {
            method: 'DELETE',
            endpoint: API.mileage.report.templates,
            types: [REQUEST, DELETE_REPORT_TEMPLATES, FAILURE],
            body,
            ids: body,
        },
    };
}

export function createReportTemplate(body) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.mileage.report.templates,
            types: [REQUEST, CREATE_REPORT_TEMPLATE, FAILURE],
            body,
        },
    };
}

export function uploadMileageCSV(file) {
    const body = new FormData();
    body.append('csv_file', file);

    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.mileage.csv,
            types: [REQUEST, UPLOAD_MILEAGE_CSV, FAILURE],
            body,
        },
    };
}

export function exportMileagePDF(car_ids, since, until) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.mileage.report.pdf,
            types: [REQUEST, EXPORT_MILEAGE_PDF, FAILURE],
            responseType: 'blob',
            body: {
                car_ids,
                since,
                until,
            },
        },
    };
}

export function generateMileageReport(body) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.mileage,
            types: [REQUEST, GENERATE_MILEAGE_REPORT, FAILURE],
            body,
            showSuccess: false,
        },
    };
}

export function generateGeneralVehicleReportData(option) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.vehicle,
            types: [REQUEST, GENERATE_VEHICLE_REPORT, FAILURE],
            body: { option },
            showSuccess: false,
        },
    };
}

export function generateVehicleEmissionsReport(option) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.vehicle_emissions,
            types: [REQUEST, GENERATE_VEHICLE_EMISSIONS_REPORT, FAILURE],
            body: { option },
            showSuccess: false,
        },
    };
}

export function generateChosenVehiclesMileageReportData(body) {
    const { fleet_ids, car_ids, ...rest } = body;
    let endpoint = null;

    if(fleet_ids.length) {
        body = { ...rest, fleet_ids };
        endpoint = API.reports.chosen_mileage_fleets;
    } else {
        body = { ...rest, car_ids };
        endpoint = API.reports.chosen_mileage_cars;
    }

    return {
        [CALL_API]: {
            method: 'POST',
            endpoint,
            types: [REQUEST, GENERATE_CHOSEN_VEHICLE_MILEAGE_REPORT, FAILURE],
            body,
            showSuccess: false,
        },
    };
}

export function generateGeneralFleetReportData() {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.fleets,
            types: [REQUEST, GENERATE_FLEET_GENERAL_REPORT, FAILURE],
            showSuccess: false,
        },
    };
}

export function generateCVDReportData(option) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.cvd,
            types: [REQUEST, GENERATE_CVD_REPORT, FAILURE],
            showSuccess: false,
            body: { option },
        },
    };
}

export function generateSingleFleetReportData(body) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.fleet,
            types: [REQUEST, GENERATE_SINGLE_FLEET_REPORT, FAILURE],
            body,
            showSuccess: false,
        },
    };
}

export function generateSelectedVehiclesReportData(since, until, cars) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.selected_vehicles,
            types: [REQUEST, GENERATE_SELECTED_VEHICLES_REPORT, FAILURE],
            showSuccess: false,
            body: { since, until, cars },
        },
    };
}

export function generateGeneralMileageEmissionReport(since, until) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.general_mileage_emission,
            types: [REQUEST, GENERATE_GENERAL_MILEAGE_EMISSION_REPORT, FAILURE],
            showSuccess: false,
            body: {
                since,
                until,
            },
        },
    };
}

export function exportMileageCSV(car_ids, since, until) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.mileage.report.csv,
            types: [REQUEST, EXPORT_MILEAGE_CSV, FAILURE],
            responseType: 'blob',
            body: {
                car_ids,
                since,
                until,
            },
        },
    };
}

export function getMileageCSVTemplate() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.mileage.csv_template,
            types: [REQUEST, GET_MILEAGE_CSV_TEMPLATE, FAILURE],
            responseType: 'blob',
            showError: true,
        },
    };
}

export function getMileageSummaryData(option) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.mileage.summary,
            types: [REQUEST, GET_MILEAGE_INITIAL_DATA, FAILURE],
            showError: true,
            showSuccess: false,
            body: { option },
            showLoading: false,
        },
    };
}

export function exportDrivingJournalCSV(since, until) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.mileage.driving_journal,
            types: [REQUEST, EXPORT_DRIVING_JOURNAL_CSV, FAILURE],
            responseType: 'blob',
            body: { since, until },
        },
    };
}

export function uploadRefuelCSV(file) {
    const body = new FormData();
    body.append('csv_file', file);

    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.mileage.csv_refuel,
            types: [REQUEST, UPLOAD_REFUEL_CSV, FAILURE],
            body,
        },
    };
}

export function getRefuelCSVTemplate() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.mileage.csv_refuel_template,
            types: [REQUEST, GET_REFUEL_CSV_TEMPLATE, FAILURE],
            responseType: 'blob',
            showError: true,
        },
    };
}
export function downloadReportCsv(body) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.csv,
            types: [REQUEST, GET_REPORT_CSV, FAILURE],
            responseType: 'blob',
            showError: true,
            body,
        },
    };
}

export function downloadReportXls(body) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.xls,
            types: [REQUEST, GET_REPORT_XLS, FAILURE],
            responseType: 'blob',
            showError: true,
            body,
        },
    };
}

export function generateWorkingMachineReport(since, until, car_ids, option) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.reports.working_machines,
            types: [REQUEST, GET_WORKING_MACHINE_REPORT, FAILURE],
            body: { since, until, car_ids, option },
            showError: true,
            showSuccess: false,
        },
    };
}
