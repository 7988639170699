import moment from 'moment';
import _ from 'lodash';
import { LOGIN_SUCCESS } from 'components/Auth/actions.js';
import {
    REQUEST,
    FAILURE,
    SAVE_USER,
    SAVE_PROFILE,
    GET_PROFILE,
    GET_USER,
    UPLOAD_ICON,
    GET_COUNTRIES,
    GET_EVENTS,
    GET_TIER,
    UPGRADE_TIER,
    SET_NOTIFIED_ABOUT_TIER_LIMIT,
} from './actions.js';

const initialState = {
    groupProfile: {
        icon: localStorage.getItem('icon'),
        name: '',
        tax_number: '',
        country_code: 'FI',
        city: '',
        postal_code: '',
        address: '',
        phone_code: '',
        phone: '',
    },
    userProfile: {},
    countries: [],
    events: [],
    tier: {},
    canBeNotifiedAboutBeingOverTierLimit: false,
};

function account(state = initialState, action) {
    switch(action.type) {
        case REQUEST: {
            return {
                ...state,
                loading: true,
            };
        }
        case FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case SAVE_USER: {
            return {
                ...state,
                loading: false,
            };
        }
        case SAVE_PROFILE: {
            return {
                ...state,
                loading: false,
            };
        }
        case GET_PROFILE: {
            const { icon_file, ...rest } = action.response;

            const icon = icon_file?.content_base64;
            icon && localStorage.setItem('icon', icon);

            return {
                ...state,
                loading: false,
                groupProfile: {
                    ...state.groupProfile,
                    ...rest,
                    icon,
                },
            };
        }
        case GET_USER: {
            return {
                ...state,
                loading: false,
                userProfile: action.response,
            };
        }
        case UPLOAD_ICON: {
            const { content_base64 } = action.response.icon_file;
            localStorage.setItem('icon', content_base64);

            return {
                ...state,
                groupProfile: {
                    ...state.groupProfile,
                    icon: content_base64,
                },
            };
        }
        case GET_COUNTRIES: {
            const countries = [];

            action.response.forEach(country => {
                const countryCode = Object.keys(country)[0];
                const formattedCountry = { value: countryCode, label: country[countryCode] };
                countries.push(formattedCountry);
            });

            return {
                ...state,
                countries,
            };
        }
        case GET_EVENTS: {
            const groupedEvents = _.groupBy(action.response, event => moment(event.created_at).startOf('day').format());
            const eventArray = [];
            for(const key in groupedEvents) {
                eventArray.push({ date: key, events: groupedEvents[key] });
            }
            const sortedEvents = eventArray.sort((a, b) => new Date(b.date) - new Date(a.date));

            return {
                ...state,
                events: sortedEvents,
            };
        }
        case GET_TIER:
        case UPGRADE_TIER: {
            const { tier, cars_requests_count, cars_amount_count } = action.response;

            return {
                ...state,
                tier: {
                    ...tier,
                    cars_requests_count,
                    cars_amount_count,
                },
            };
        }
        case SET_NOTIFIED_ABOUT_TIER_LIMIT: {
            return {
                ...state,
                canBeNotifiedAboutBeingOverTierLimit: false,
            };
        }
        case LOGIN_SUCCESS: {
            return {
                ...state,
                canBeNotifiedAboutBeingOverTierLimit: true,
            };
        }
        default: {
            return state;
        }
    }
}

export default account;
