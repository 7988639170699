import { combineReducers } from 'redux';
import Mileage from 'components/Reports/reducers.js';
import Dashboard from 'components/Dashboard/reducers';
import Vehicles from 'components/Fleets/VehiclesView/vehicleReducers.js';
import Account from 'components/Account/reducers';
import Auth from 'components/Auth/reducers';
import Fleet from 'components/Fleets/fleetsReducers';
import Help from 'components/HelpCenter/reducers.js';
import CVM from 'components/CVM/reducers.js';
import Booking from 'components/Booking/reducers.js';

import Layout from 'components/layouts/reducers';
import ServiceWorker from 'sw/reducers';

const appReducer = combineReducers({
    ServiceWorker,
    Layout,
    Auth,
    Dashboard,
    Vehicles,
    Fleet,
    Mileage,
    Account,
    Help,
    CVM,
    Booking,
});

const makeRootReducer = (state, action) => (
    action.type === 'LOGOUT'
        ? appReducer(undefined, action)
        : appReducer(state, action)
);

export default makeRootReducer;
