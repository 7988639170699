import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import api from 'middleware/api';
import async from 'middleware/async';
import makeRootReducer from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default (initialState = {}) => {
    const middleware = [thunk, api, async];

    const store = createStore(
        makeRootReducer,
        initialState,
        composeEnhancers(
            applyMiddleware(...middleware),
        ),
    );

    store.asyncReducers = {};

    return store;
};
