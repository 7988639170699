import { API } from 'constants.js';
import { CALL_API } from 'middleware/api.js';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const CREATE_DRIVER = 'CREATE_DRIVER';
export const EDIT_DRIVERS = 'EDIT_DRIVERS';
export const LIST_DRIVERS = 'LIST_DRIVERS';
export const DELETE_DRIVER = 'DELETE_DRIVER';
export const GET_MILEAGE_FIELDS = 'GET_MILEAGE_FIELDS';
export const SET_MILEAGE_FIELDS = 'SET_MILEAGE_FIELDS';
export const GET_JOURNAL_CARS = 'GET_JOURNAL_CARS';
export const GET_JOURNAL_USERS = 'GET_JOURNAL_USERS';
export const GET_JOURNAL_MILEAGE = 'GET_JOURNAL_MILEAGE';
export const UPDATE_MILEAGE = 'UPDATE_MILEAGE';
export const DELETE_MILEAGE = 'DELETE_MILEAGE';

export function getDrivers(org_or_company) {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.cvm.list.replace(':org_or_company', org_or_company),
            types: [REQUEST, LIST_DRIVERS, FAILURE],
        },
    };
}

export function createDriver(body, org_or_company) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.cvm.create.replace(':org_or_company', org_or_company),
            types: [REQUEST, CREATE_DRIVER, FAILURE],
            body,
        },
    };
}

export function editDrivers(body, org_or_company) {
    return {
        [CALL_API]: {
            method: 'PATCH',
            endpoint: API.cvm.list.replace(':org_or_company', org_or_company),
            types: [REQUEST, EDIT_DRIVERS, FAILURE],
            body,
        },
    };
}

export function deleteDriver(org_or_company, id) {
    return {
        [CALL_API]: {
            method: 'DELETE',
            endpoint: API.cvm.delete.replace(':org_or_company', org_or_company).replace(':id', id),
            types: [REQUEST, DELETE_DRIVER, FAILURE],
            id,
        },
    };
}

export function getMileageFields() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.cvm.mileage,
            types: [REQUEST, GET_MILEAGE_FIELDS, FAILURE],
        },
    };
}

export function setMileageFields(body) {
    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: API.cvm.mileage,
            types: [REQUEST, SET_MILEAGE_FIELDS, FAILURE],
            body,
        },
    };
}

export function getJournalCars() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.cvm.journal_car,
            types: [REQUEST, GET_JOURNAL_CARS, FAILURE],
        },
    };
}

export function getJournalUsers() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.cvm.journal_user,
            types: [REQUEST, GET_JOURNAL_USERS, FAILURE],
        },
    };
}

export function getJournalMileage(date) {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.cvm.journal_mileage.replace(':date', date),
            types: [REQUEST, GET_JOURNAL_MILEAGE, FAILURE],
        },
    };
}

export function updateMileage(id, body) {
    return {
        [CALL_API]: {
            method: 'PATCH',
            endpoint: API.cvm.journal_edit.replace(':id', id),
            types: [REQUEST, UPDATE_MILEAGE, FAILURE],
            body,
            id,
        },
    };
}

export function deleteMileage(id) {
    return {
        [CALL_API]: {
            method: 'DELETE',
            endpoint: API.cvm.journal_edit.replace(':id', id),
            types: [REQUEST, DELETE_MILEAGE, FAILURE],
            id,
        },
    };
}
