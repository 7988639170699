import { connect } from 'react-redux';
import { t } from 'ttag';
import { decideCookies } from 'components/layouts/actions.js';
import './index.scss';

const CookieDisclaimer = ({ cookies, decideCookies }) => {
    if(cookies !== null) return null;

    return (
        <aside id='cookie-disclaimer'>
            <span className='cookie-disclaimer-body'>
                {t`COOKIE_DISCLAIMER`}
            </span>
            <button type='button' className='button button--secondary' onClick={() => decideCookies(false)}>
                {t`DECLINE`}
            </button>
            <button type='button' className='button button--primary' onClick={() => decideCookies(true)}>
                {t`ACCEPT`}
            </button>
        </aside>
    );
};

const mapStateToProps = state => ({ cookies: state.Layout.cookies });
export default connect(mapStateToProps, { decideCookies })(CookieDisclaimer);
