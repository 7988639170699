import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';
import { fetchLocale } from '../../utils';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const GET_VIDEOS = 'GET_VIDEOS';
export const GET_FAQ = 'GET_FAQ';
export const SEND_MESSAGE = 'SEND_MESSAGE';

export function getVideos() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.help.videos.replace(':locale', fetchLocale()),
            types: [REQUEST, GET_VIDEOS, FAILURE],
        },
    };
}

export function getFaq() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.help.faq.replace(':locale', fetchLocale()),
            types: [REQUEST, GET_FAQ, FAILURE],
        },
    };
}


export function sendMessage(body) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.help.message,
            body,
            types: [REQUEST, SEND_MESSAGE, FAILURE],
        },
    };
}
