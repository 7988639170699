import { useLocation } from 'react-router-dom';

import GlobalNavigationBar from '../GlobalNavigationBar';
import '../CoreLayout/index.scss';

/**
 * This loading component used as a root wrapper for rendering empty page when we are loading a route.
 */

const RouteLoading = () => {
    const location = useLocation();

    // Paths with these strings shouldn't show the global navigation bar
    if(['noreg', 'login'].some(str => location.pathname.includes(str))) return <div />;

    return (
        <div className='core-wrapper'>
            <GlobalNavigationBar activeTab={location.state} />
        </div>
    );
};

export default (RouteLoading);
