import {
    REQUEST,
    FAILURE,
    LIST_BOOKINGS,
    FIND_AVAILABLE_CARS,
    LIST_USERS,
    CREATE_NEW_BOOKING,
    DELETE_BOOKING,
} from './actions.js';

const initialState = {
    bookings: [],
    availableVehicles: [],
    users: [],
};

function booking(state = initialState, action) {
    switch(action.type) {
        case REQUEST: {
            return { ...state };
        }
        case FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case LIST_BOOKINGS: {
            return {
                ...state,
                bookings: action.response,
            };
        }
        case FIND_AVAILABLE_CARS: {
            return {
                ...state,
                availableVehicles: action.response,
            };
        }
        case LIST_USERS: {
            return {
                ...state,
                users: action.response,
            };
        }
        case CREATE_NEW_BOOKING: {
            return {
                ...state,
                bookings: [...state.bookings, action.response],
            };
        }
        case DELETE_BOOKING: {
            return {
                ...state,
                bookings: state.bookings.filter(b => b.id !== action.id),
            };
        }
        default: { return state; }
    }
}

export default booking;
