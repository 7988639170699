import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const LOGOUT = 'LOGOUT';
export const REGISTER = 'REGISTER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const RESET_PASSWORD = 'RESET_PASSWORD';
export const SET_NEW_PASSWORD = 'SET_NEW_PASSWORD';

export function login(credentials) {
    return {
        [CALL_API]: {
            endpoint: API.auth.token,
            method: 'POST',
            types: [REQUEST, LOGIN_SUCCESS, FAILURE],
            showSuccess: false,
            body: {
                username: credentials.username,
                password: credentials.password,
            },
        },
    };
}

export function logout() {
    return { type: 'LOGOUT' };
}

export function changePassword(passwords) {
    return {
        [CALL_API]: {
            endpoint: API.user.change,
            method: 'PUT',
            types: [REQUEST, CHANGE_PASSWORD, FAILURE],
            showSuccess: true,
            body: {
                old_password: passwords.password,
                new_password: passwords.new_password,
            },
        },
    };
}

export function register({
    email,
    first_name,
    last_name,
    password,
    password_confirm,
    phone,
    name,
    country_code,
}) {
    return {
        [CALL_API]: {
            endpoint: API.user.register,
            method: 'POST',
            types: [REQUEST, REGISTER, FAILURE],
            body: {
                email,
                first_name,
                last_name,
                password,
                password_confirm,
                phone,
                freeware: {
                    name,
                    country_code,
                },
            },
        },
    };
}

export function resetPassword(username, showSuccess = false, showError = false) {
    return {
        [CALL_API]: {
            endpoint: API.user.reset,
            method: 'PUT',
            types: [REQUEST, RESET_PASSWORD, FAILURE],
            showSuccess,
            showError,
            body: { username },
        },
    };
}

export function resetPasswordCVM(username, showSuccess = false, showError = false) {
    return {
        [CALL_API]: {
            endpoint: API.user.reset_cvm,
            method: 'PUT',
            types: [REQUEST, RESET_PASSWORD, FAILURE],
            showSuccess,
            showError,
            body: { username },
        },
    };
}

export function setNewPassword(token, new_password) {
    return {
        [CALL_API]: {
            endpoint: API.user.new_password.replace(':token', token),
            method: 'PUT',
            types: [REQUEST, SET_NEW_PASSWORD, FAILURE],
            body: { new_password },
            showSuccess: false,
            showError: false,
        },
    };
}
