import moment from 'moment';
import { t, jt } from 'ttag';
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ReactComponent as IconInspection } from 'static/Notifications/icon-inspection.svg';
import { ReactComponent as IconTick } from 'static/Common/icon-tick.svg';
import { ReactComponent as IconLoading } from 'static/Notifications/icon-loading.svg';
import { ReactComponent as IconQuestion } from 'static/Common/icon-question-mark.svg';
import { ReactComponent as IconShare } from 'static/Common/icon-share.svg';
import { ReactComponent as IconClose } from 'static/Common/icon-close.svg';
import { downloadHttpResponseFile } from '../../utils';
import { getCSVError } from './actions';

// rq_job_status = str?: queued, finished, failed, started, deferred, scheduled, stopped, canceled
const ONGOING_STATUSES = ['queued', 'started', 'deferred', 'scheduled'];
const FAILED_STATUSES = ['failed', 'stopped', 'canceled'];

export const NOTIFICATION_COLORS = () => ({
    CAR_INSPECTION: 'var(--chart-color-matte-blue)',
    ALCOLOCK_INSPECTION: 'var(--chart-color-matte-blue)',
    TACHOGRAPH_INSPECTION: 'var(--chart-color-matte-blue)',
    CARS_REQUEST_RESET: 'var(--chart-color-matte-green)',
    CARS_CSV_UPLOAD: 'var(--chart-color-matte-green)',
    MILEAGE_CSV_UPLOAD: 'var(--chart-color-matte-green)',
    REFUEL_CSV_UPLOAD: 'var(--chart-color-matte-green)',
    PROCUREMENT_CLOSED: 'var(--chart-color-matte-red)',
    PROPOSAL_SELECTED: 'var(--chart-color-matte-red)',
    SHARED_FLEET_UPDATED: 'var(--chart-color-matte-red)',
    FLEET_SHARE_SEND: 'var(--chart-color-matte-green)',
});

export const getNotificationIcon = ({ key, rq_job_status }) => {
    switch(key) {
        case 'CAR_INSPECTION':
        case 'ALCOLOCK_INSPECTION':
        case 'TACHOGRAPH_INSPECTION': { return <IconInspection />; }

        case 'PROCUREMENT_CLOSED':
        case 'PROPOSAL_SELECTED': { return null; }

        case 'CARS_CSV_UPLOAD':
        case 'MILEAGE_CSV_UPLOAD':
        case 'REFUEL_CSV_UPLOAD': {
            if(rq_job_status === 'finished') return <IconTick />;
            if(ONGOING_STATUSES.includes(rq_job_status)) return <IconLoading />;
            if(FAILED_STATUSES.includes(rq_job_status)) return <IconClose />;

            return <IconQuestion />;
        }

        case 'FLEET_SHARE_SEND':
        case 'SHARED_FLEET_UPDATED': { return <IconShare />; }

        case 'CARS_REQUEST_RESET': { return <IconTick />; }

        default: {
            console.warn('Unexpected `notification.key` in `getNotificationIcon`', key);
            return null;
        }
    }
};

export const getNotificationSubject = ({ key, details, csv_file_name }) => {
    const { car, procurement, proposal, fleet, fleets } = details;

    switch(key) {
        case 'CAR_INSPECTION':
        case 'ALCOLOCK_INSPECTION':
        case 'TACHOGRAPH_INSPECTION': { return car?.car_data?.plate || t`N/A`; }

        case 'PROCUREMENT_CLOSED':
        case 'PROPOSAL_SELECTED': { return procurement?.name || proposal?.name || '-'; }

        case 'CARS_CSV_UPLOAD':
        case 'MILEAGE_CSV_UPLOAD':
        case 'REFUEL_CSV_UPLOAD': { return csv_file_name; }

        case 'SHARED_FLEET_UPDATED': { return fleet?.name; }
        case 'CARS_REQUEST_RESET': { return t`TITLE_CARS_REQUEST_RESET`; }
        case 'FLEET_SHARE_SEND': { return fleets[0].name; }

        default: {
            console.warn('Unexpected `notification.key` in `getNotificationSubject`', key);
            return null;
        }
    }
};

export const getNotificationText = ({ key, details, rq_job_status }) => {
    switch(key) {
        case 'CAR_INSPECTION':
        case 'ALCOLOCK_INSPECTION':
        case 'TACHOGRAPH_INSPECTION': {
            const {
                next_alcolock_inspection,
                next_tachograph_inspection,
                next_tech_inspection_start,
            } = details.car.car_data || details.car.car_spec;

            const formattedDate = moment(
                next_alcolock_inspection || next_tachograph_inspection || next_tech_inspection_start,
            ).format('L');

            if(next_alcolock_inspection) return t`ALCOLOCK_INSPECTION_ON_${formattedDate}`;
            if(next_tachograph_inspection) return t`TACHOGRAPH_INSPECTION_ON_${formattedDate}`;
            if(next_tech_inspection_start) return t`TECH_INSPECTION_START_ON_${formattedDate}`;

            console.warn('Inspection notification with unexpected inspection date in `getNotificationText`,', details);
            return null;
        }

        case 'PROCUREMENT_CLOSED':
        case 'PROPOSAL_SELECTED': { return null; }

        case 'CARS_CSV_UPLOAD': {
            if(rq_job_status === 'finished') return t`CARS_CSV_UPLOAD_FINISHED`;
            if(ONGOING_STATUSES.includes(rq_job_status)) return t`CARS_CSV_UPLOAD_ONGOING`;
            if(FAILED_STATUSES.includes(rq_job_status)) return t`CARS_CSV_UPLOAD_FAILED`;

            return t`CARS_CSV_UPLOAD_STATUS_UNKNOWN`;
        }

        case 'MILEAGE_CSV_UPLOAD': {
            if(rq_job_status === 'finished') return t`MILEAGE_CSV_UPLOAD_FINISHED`;
            if(ONGOING_STATUSES.includes(rq_job_status)) return t`MILEAGE_CSV_UPLOAD_ONGOING`;
            if(FAILED_STATUSES.includes(rq_job_status)) return t`MILEAGE_CSV_UPLOAD_FAILED`;

            return t`MILEAGE_CSV_UPLOAD_STATUS_UNKNOWN`;
        }

        case 'REFUEL_CSV_UPLOAD': {
            if(rq_job_status === 'finished') return t`REFUEL_CSV_UPLOAD_FINISHED`;
            if(ONGOING_STATUSES.includes(rq_job_status)) return t`REFUEL_CSV_UPLOAD_ONGOING`;
            if(FAILED_STATUSES.includes(rq_job_status)) return t`REFUEL_CSV_UPLOAD_FAILED`;

            return t`REFUEL_CSV_UPLOAD_STATUS_UNKNOWN`;
        }

        case 'SHARED_FLEET_UPDATED': { return t`SHARED_FLEET_WAS_UPDATED`; }
        case 'CARS_REQUEST_RESET': { return t`TITLE_CARS_REQUEST_RESET`; }
        case 'FLEET_SHARE_SEND': { return t`FLEET_WAS_SHARED_TO_YOU`; }

        default: {
            console.warn('Unexpected `notification.key` in `getNotificationText`', key);
            return null;
        }
    }
};

export const getNotificationModalTitle = notification => {
    switch(notification.key) {
        case 'CAR_INSPECTION':
        case 'ALCOLOCK_INSPECTION':
        case 'TACHOGRAPH_INSPECTION': {
            const { details } = notification;
            const {
                next_alcolock_inspection,
                next_tachograph_inspection,
                next_tech_inspection_start,
            } = details.car.car_data || details.car.car_spec;

            if(next_alcolock_inspection) return t`ALCOLOCK_INSPECTION_REMINDER`;
            if(next_tachograph_inspection) return t`TACHOGRAPH_INSPECTION_REMINDER`;
            if(next_tech_inspection_start) return t`TECH_INSPECTION_START_REMINDER`;

            console.warn('Inspection notification with unknown inspection date in getNotificationModalTitle', details);
            return t`INSPECTION_START_REMINDER`;
        }

        case 'PROCUREMENT_CLOSED':
        case 'PROPOSAL_SELECTED': { return null; }

        case 'CARS_CSV_UPLOAD':
        case 'MILEAGE_CSV_UPLOAD':
        case 'REFUEL_CSV_UPLOAD': { return getNotificationText(notification); }

        case 'SHARED_FLEET_UPDATED': { return t`SHARED_FLEET_UPDATE`; }
        case 'FLEET_SHARE_SEND': { return t`FLEET_WAS_SHARED_TO_YOU`; }

        default: {
            console.warn('Unexpected `notification.key` in `getNotificationModalTitle`', notification.key);
            return null;
        }
    }
};

export const COLOURS = [
    'file-color-sky-blue',
    'file-color-orange',
    'file-color-purple',
    'chart-color-matte-purple',
    'file-color-yellow',
    'chart-color-matte-red',
    'chart-color-matte-yellow',
    'mono-0-black',
    'file-color-light-blue',
    'chart-color-matte-blue',
    'chart-color-matte-orange',
    'chart-color-matte-green',
    'file-color-red',
    null,
];

export const SORTS = () => [
    { value: 'name_asc', label: t`SORT_NAME_(ASCENDING)`, fn: key => (a, b) => a[key].localeCompare(b[key]) },
    { value: 'name_desc', label: t`SORT_NAME_(DESCENDING)`, fn: key => (a, b) => b[key].localeCompare(a[key]) },
    { value: 'numeric_asc', label: t`SORT_VALUE_(ASCENDING)`, fn: key => (a, b) => a[key] - b[key] },
    { value: 'numeric_desc', label: t`SORT_VALUE_(DESCENDING)`, fn: key => (a, b) => b[key] - a[key] },
];

export const EMISSION_COMPARISONS = isFwee => [
    {
        value: 'label-emissions',
        label: t`LABEL_EMISSIONS`,
        unit: 'g/km',
        category: 'labels',
        keyName: 'emissions_avg',
        sortKeyNames: {
            name_asc: 'name',
            name_desc: 'name',
            numeric_asc: 'emissions_avg',
            numeric_desc: 'emissions_avg',
        },
        disabled: isFwee,
        className: isFwee ? 'fwee-disabled' : null,
        'data-premium-tooltip': isFwee ? t`FWEEWAWE_LABELS` : '',
    },
    {
        value: 'label-clean',
        label: t`LABEL_CLEAN_LEVELS`,
        unit: '%',
        category: 'labels',
        keyName: 'perc_clean',
        sortKeyNames: {
            name_asc: 'name',
            name_desc: 'name',
            numeric_asc: 'perc_clean',
            numeric_desc: 'perc_clean',
        },
        disabled: isFwee,
        className: isFwee ? 'fwee-disabled' : null,
        'data-premium-tooltip': isFwee ? t`FWEEWAWE_LABELS` : '',
    },
    {
        value: 'fleet-emissions',
        label: t`FLEET_EMISSIONS`,
        unit: 'g/km',
        category: 'fleets',
        keyName: 'emissions_avg',
        sortKeyNames: {
            name_asc: 'name',
            name_desc: 'name',
            numeric_asc: 'emissions_avg',
            numeric_desc: 'emissions_avg',
        },
    },
    {
        value: 'fleet-clean',
        label: t`FLEET_CLEAN_LEVELS`,
        category: 'fleets',
        keyName: 'perc_clean',
        unit: '%',
        sortKeyNames: {
            name_asc: 'name',
            name_desc: 'name',
            numeric_asc: 'perc_clean',
            numeric_desc: 'perc_clean',
        },
    },
    {
        value: 'vehicles-emissions',
        label: t`VEHICLE_EMISSIONS`,
        category: 'vehicles',
        keyName: 'emissions',
        unit: 'g/km',
        sortKeyNames: {
            name_asc: 'plate',
            name_desc: 'plate',
            numeric_asc: 'emissions',
            numeric_desc: 'emissions',
        },
    },
];

export const MILEAGE_COMPARISONS = isFwee => [
    {
        value: 'label-mileages',
        label: t`LABEL_MILEAGES`,
        unit: 'km',
        category: 'labels',
        keyName: 'mileage',
        sortKeyNames: {
            name_asc: 'name',
            name_desc: 'name',
            numeric_asc: 'total_km',
            numeric_desc: 'total_km',
        },
        disabled: isFwee,
        className: isFwee ? 'fwee-disabled' : null,
        'data-premium-tooltip': isFwee ? t`FWEEWAWE_LABELS` : '',
    },
    {
        value: 'fleet-mileages',
        label: t`FLEET_MILEAGES`,
        category: 'fleets',
        keyName: 'mileage',
        unit: 'km',
        sortKeyNames: {
            name_asc: 'name',
            name_desc: 'name',
            numeric_asc: 'total_km',
            numeric_desc: 'total_km',
        },
    },
    {
        value: 'vehicles-mileages',
        label: t`VEHICLE_MILEAGES`,
        category: 'vehicles',
        keyName: 'mileage',
        unit: 'km',
        sortKeyNames: {
            name_asc: 'plate',
            name_desc: 'plate',
            numeric_asc: 'total_km',
            numeric_desc: 'total_km',
        },
    },
];

export const VEHICLE_CLASS_SORTS = () => [
    { value: 'cars_number', label: t`VEHICLE_COUNT`, order: -1 },
    { value: 'vehicle_class', label: t`ALPHABETICAL`, order: 1 },
];

export const NotificationToContent = notification => {
    const dispatch = useDispatch();

    if(!notification) return null;

    const {
        rq_job_status,
        key,
        details_error_count,
        details_total_count,
        id,
        details,
        csv_file_name,
        created_at,
    } = notification;

    switch(key) {
        case 'CAR_INSPECTION':
        case 'ALCOLOCK_INSPECTION':
        case 'TACHOGRAPH_INSPECTION': {
            // TODO: Implement links to vehicles view with specific vehicle's details open
            const plate = <b key='1'>{details?.car.car_data.plate || '-'}</b>;

            return ({
                content: <p>{jt`INSPECTION_UPCOMING_FOR_${plate}`}</p>,
                button: <Link to='/vehicles' className='button button--primary'>{t`GO_TO_VEHICLES`}</Link>,
            });
        }

        case 'CARS_REQUEST_RESET': { return <p>{t`USER_TIER_REQUESTS_RESET_ENJOY`}</p>; }

        case 'CARS_CSV_UPLOAD':
        case 'MILEAGE_CSV_UPLOAD':
        case 'REFUEL_CSV_UPLOAD': {
            const filename = <b key='1'>{csv_file_name}</b>;
            const time = <b key='2'>{moment(created_at).format('L LT')}</b>;

            switch(rq_job_status) {
                case 'finished': {
                    // It's unsafe if we don't default both of them to 0
                    const successfulCount = <b key='3'>{(details_total_count || 0) - (details_error_count || 0)}</b>;
                    const errorCount = <b key='4'>{details_error_count}</b>;

                    const handleDownload = () => dispatch(getCSVError(id)).then(res => {
                        if(!res || res.error) return console.error('Error response from `getCSVError` action', res);

                        downloadHttpResponseFile(res, `${t`CSV_ERROR_FILE_NAME`}.csv`);
                    });

                    return ({
                        content: (
                            <>
                                <p>{jt`CSV_${filename}_UPLOAD_SUCCESS_ON_${time}`}</p>
                                <p>{jt`CSV_UPLOAD_SUCCESSFUL_${successfulCount}`}</p>
                                {!!details_error_count && <p>{jt`CSV_UPLOAD_FAILS_${errorCount}`}</p>}
                            </>
                        ),
                        button: (
                            <button type='button' className='button--primary' onClick={handleDownload}>
                                {(details_error_count || 0) ? t`DOWNLOAD_CSV_WITH_ERRORS` : t`DOWNLOAD_UPLOADED_FILE`}
                            </button>
                        ),
                    });
                }
                case 'queued':
                case 'ongoing':
                case 'started':
                case 'deferred':
                case 'scheduled': {
                    return (
                        <>
                            <p>{jt`FILE_${filename}_UPLOAD_ONGOING`}</p>
                            <p>{jt`FILE_ONGOING_UPLOAD_STATUS_LAST_UPDATED_${time}`}</p>
                        </>
                    );
                }
                case 'failed':
                case 'stopped':
                case 'canceled': {
                    const path = key === 'CARS_CSV_UPLOAD' ? '/cars' : '/mileage';

                    return ({
                        content: (
                            <>
                                <p>{jt`FILE_${filename}_UPLOADED_ON_${time}_FAILED`}</p>
                                <p>{t`FILE_UPLOAD_FAILED_EXPLANATION`}</p>
                            </>
                        ),
                        button: <Link to={path} className='button button--primary'>{t`RETRY`}</Link>,
                    });
                }
                default: {
                    return ({
                        content: (
                            <>
                                <p>{jt`FILE_${filename}_UPLOADED_ON_${time}_UNKNOWN_STATUS`}</p>
                                <p>{t`FILE_UPLOAD_UNKNOWN_STATUS_EXPLANATION`}</p>
                            </>
                        ),
                        button: <Link to='/mileage' className='button button--primary'>{t`RETRY`}</Link>,
                    });
                }
            }
        }

        case 'PROCUREMENT_CLOSED':
        case 'PROPOSAL_SELECTED': { break; }

        case 'SHARED_FLEET_UPDATED': {
            const { fleet, organization, company } = details;

            const customerName = organization?.name || company?.name;
            const customer = <b key='1'>{customerName}</b>;
            const fleetName = <Link key='2' to={`/fleets/${fleet.id}/vehicles`}>{fleet.name}</Link>;
            const time = <b key='3'>{moment(fleet.updated_at).format('L LT')}</b>;

            return customerName
                ? <p>{jt`${customer}_HAS_EDITED_SHARED_${fleetName}_AT_${time}`}</p>
                : <p>{jt`SHARED_FLEET_${fleetName}_WAS_EDITED_AT_${time}`}</p>;
        }

        case 'FLEET_SHARE_SEND': {
            const { organization, company, fleets } = details;
            const customer = <b key='1'>{organization.name || company.name}</b>;

            return fleets.map(f => {
                const fleet = <Link key='2' to={`/fleets/${f.id}/vehicles`}>{f.name}</Link>;
                const cars = <b key='3'>{f.cars.length}</b>;

                return <p key={f.id}>{jt`USER_${customer}_SHARED_FLEET_${fleet}_WITH_${cars}`}</p>;
            });
        }

        default: {
            console.error('Unexpected `notification.key` in `NotificationToContent`', key);
            return <p>{t`ERROR_GETTING_NOTIFICATION_DATA_SORRY`}</p>;
        }
    }
};
