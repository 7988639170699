import { t } from 'ttag';
import { API } from 'constants.js';
import { CALL_API } from 'middleware/api';
import { getParsedLSItemOr } from 'components/Auth/reducers.js';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const SET_COLUMN = 'SET_COLUMN';

export const NEW_FLEET = 'NEW_FLEET';
export const GET_FLEETS = 'GET_FLEETS';
export const RENAME_FLEET = 'RENAME_FLEET';
export const DELETE_FLEET = 'DELETE_FLEET';
export const REMOVE_FLEETS = 'REMOVE_FLEETS';
export const DELETE_FLEETS = 'DELETE_FLEETS';
export const GET_FLEET_PDF = 'GET_FLEET_PDF';
export const SET_FLEET_SORT = 'SET_FLEET_SORT';
export const GET_FLEET_DETAILS = 'GET_FLEET_DETAILS';
export const SET_FLEET_COLUMNS = 'SET_FLEET_COLUMNS';
export const UPDATE_FLEET_LABELS = 'UPDATE_FLEET_LABELS';

export const GET_VEHICLES = 'GET_VEHICLES';
export const ADD_VEHICLES = 'ADD_VEHICLES';
export const COPY_VEHICLES = 'COPY_VEHICLES';
export const MOVE_VEHICLES = 'MOVE_VEHICLES';
export const RENAME_VEHICLE = 'RENAME_VEHICLE';
export const SET_FLEET_CARS = 'SET_FLEET_CARS';
export const REMOVE_VEHICLES = 'REMOVE_VEHICLES';
export const DELETE_VEHICLES = 'DELETE_VEHICLES';
export const CLEAR_FLEET_CARS = 'CLEAR_FLEET_CARS';
export const SET_VEHICLE_FUEL = 'SET_VEHICLE_FUEL';
export const SET_VEHICLE_NEDC = 'SET_VEHICLE_NEDC';
export const SET_VEHICLE_WLTP = 'SET_VEHICLE_WLTP';
export const GET_VEHICLE_FILE = 'GET_VEHICLE_FILE';
export const SET_VEHICLE_CLASS = 'SET_VEHICLE_CLASS';
export const SET_VEHICLE_PLATE = 'SET_VEHICLE_PLATE';
export const SET_VEHICLES_SORT = 'SET_VEHICLES_SORT';
export const GENERATE_QR_CODES = 'GENERATE_QR_CODES';
export const SET_VEHICLES_SEARCH = 'SET_VEHICLES_SEARCH';
export const SET_VEHICLES_COLUMNS = 'SET_VEHICLES_COLUMNS';
export const TOGGLE_VEHICLE_COLUMN = 'TOGGLE_VEHICLE_COLUMN';
export const SET_VEHICLE_PROCURED_AT = 'SET_VEHICLE_PROCURED_AT';

export const NEW_LABEL = 'NEW_LABEL';
export const GET_LABELS = 'GET_LABELS';
export const RENAME_LABEL = 'RENAME_LABEL';
export const DELETE_LABEL = 'DELETE_LABEL';
export const SET_LABEL_COLOR = 'SET_LABEL_COLOR';
export const GET_LABEL_FLEETS = 'GET_LABEL_FLEETS';
export const ADD_FLEET_TO_LABEL = 'ADD_FLEET_TO_LABEL';
export const COPY_FLEETS_TO_LABELS = 'COPY_FLEETS_TO_LABELS';
export const REMOVE_FLEET_FROM_LABEL = 'REMOVE_FLEET_FROM_LABEL';
export const SET_VEHICLE_INSPECTION_START = 'SET_VEHICLE_INSPECTION_START';
export const SET_VEHICLE_INSPECTION_END = 'SET_VEHICLE_INSPECTION_END';
export const SET_VEHICLE_INSPECTION_LAST = 'SET_VEHICLE_INSPECTION_LAST';
export const GET_CPVS = 'GET_CPVS';
export const SET_VEHICLE_CPVS = 'SET_VEHICLE_CPVS';
export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const SHARE_FLEET = 'SHARE_FLEET';
export const ACCEPT_SHARED_FLEET = 'ACCEPT_SHARED_FLEET';
export const REJECT_SHARED_FLEET = 'REJECT_SHARED_FLEET';
export const GET_CAR_DATA_SOURCES = 'GET_CAR_DATA_SOURCES';
export const GET_CAR_SPEC_SOURCES = 'GET_CAR_SPEC_SOURCES';

export const GET_TRAFICOM_KINDS = 'GET_TRAFICOM_KINDS';
export const GET_CAR_DATA_BY_KIND = 'GET_CAR_DATA_BY_KIND';

const getCarUpdateEndpoint = vehicle => {
    if(vehicle.car_data_id) {
        return API.vehicles.edit_car_data.replace(':id', vehicle.car_data_id);
    } else if(vehicle.car_spec_id) {
        return API.vehicles.edit_car_spec.replace(':id', vehicle.car_spec_id);
    } else {
        console.warn('This should not be happening. Vehicle missing car_data_id and car_spec_id', vehicle);
        return '';
    }
};

export function getFleets(updateList = true) {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            endpoint: isFwee ? API.fweewawe.fleets.list : API.fleets.list,
            method: 'GET',
            types: [REQUEST, GET_FLEETS, FAILURE],
            updateList,
        },
    };
}

export function newFleet(cars, name) {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            endpoint: isFwee ? API.fweewawe.fleets.base : API.fleets.base,
            method: 'POST',
            body: {
                name: name || t`NEW_FLEET`,
                ...cars,
            },
            types: [REQUEST, NEW_FLEET, FAILURE],
        },
    };
}

export function renameFleet(id, name) {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            endpoint: (isFwee ? API.fweewawe.fleets.detail : API.fleets.detail).replace(':id', id),
            method: 'PATCH',
            body: { name },
            types: [REQUEST, RENAME_FLEET, FAILURE],
        },
    };
}

export function getFleetDetails(id) {
    const isFwee = getParsedLSItemOr('is_fweewawe');
    id = parseInt(id, 10);

    return {
        [CALL_API]: {
            endpoint: (isFwee ? API.fweewawe.fleets.detail : API.fleets.detail).replace(':id', id),
            method: 'GET',
            types: [REQUEST, GET_FLEET_DETAILS, FAILURE],
            id,
        },
    };
}

export function setFleetCars(cars, id) {
    const isFwee = getParsedLSItemOr('is_fweewawe');
    id = parseInt(id, 10);

    return {
        [CALL_API]: {
            endpoint: (isFwee ? API.fweewawe.fleets.detail : API.fleets.detail).replace(':id', id),
            method: 'PUT',
            body: cars,
            types: [REQUEST, SET_FLEET_CARS, FAILURE],
            id,
        },
    };
}

export function clearFleetCars() {
    return { type: CLEAR_FLEET_CARS };
}

export function setFleetSort(sort) {
    return {
        type: SET_FLEET_SORT,
        sort,
    };
}

export function getVehicles() {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            endpoint: isFwee ? API.fweewawe.vehicles.list : API.vehicles.list,
            method: 'GET',
            types: [REQUEST, GET_VEHICLES, FAILURE],
        },
    };
}

export function setVehiclesSort(sort, i, groupBy) {
    return {
        type: SET_VEHICLES_SORT,
        sort,
        i,
        groupBy,
    };
}

export function setVehiclesSearch(searchText) {
    return {
        type: SET_VEHICLES_SEARCH,
        searchText,
    };
}

export function setColumn(index, field) {
    return {
        type: SET_COLUMN,
        index,
        field,
    };
}

export function deleteFleet(id) {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            endpoint: (isFwee ? API.fweewawe.fleets.detail : API.fleets.detail).replace(':id', id),
            method: 'DELETE',
            types: [REQUEST, DELETE_FLEET, FAILURE],
            id,
        },
    };
}

export function addCars(cars) {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            endpoint: isFwee ? API.fweewawe.vehicles.list : API.vehicles.list,
            method: 'POST',
            types: [REQUEST, ADD_VEHICLES, FAILURE],
            body: cars,
        },
    };
}

export function deleteCars(arrCarIDs) {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            endpoint: isFwee ? API.fweewawe.vehicles.list : API.vehicles.list,
            method: 'DELETE',
            types: [REQUEST, DELETE_VEHICLES, FAILURE],
            body: arrCarIDs,
            arrCarIDs,
        },
    };
}

export function getLabels() {
    return {
        [CALL_API]: {
            endpoint: API.labels.list,
            method: 'GET',
            types: [REQUEST, GET_LABELS, FAILURE],
        },
    };
}

export function getLabelFleets(id) {
    return {
        [CALL_API]: {
            endpoint: API.labels.fleets.replace(':id', id),
            method: 'GET',
            types: [REQUEST, GET_LABEL_FLEETS, FAILURE],
        },
    };
}

export function renameLabel(id, name) {
    return {
        [CALL_API]: {
            endpoint: API.labels.detail.replace(':id', id),
            method: 'PATCH',
            types: [REQUEST, RENAME_LABEL, FAILURE],
            body: { name },
        },
    };
}

export function updateFleetLabels(fleetID, label_ids) {
    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: API.fleets.detail.replace(':id', fleetID),
            types: [REQUEST, UPDATE_FLEET_LABELS, FAILURE],
            body: {
                label_ids,
            },
            fleetID,
        },
    };
}

export function newLabel(label) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.labels.list,
            types: [REQUEST, NEW_LABEL, FAILURE],
            body: {
                name: label.name || t`NEW_LABEL`,
                color: label.color,
            },
        },
    };
}

export function deleteLabel(id) {
    return {
        [CALL_API]: {
            endpoint: API.labels.detail.replace(':id', id),
            method: 'DELETE',
            types: [REQUEST, DELETE_LABEL, FAILURE],
            id,
        },
    };
}

export function setLabelColor(id, color) {
    return {
        [CALL_API]: {
            endpoint: API.labels.detail.replace(':id', id),
            method: 'PATCH',
            types: [REQUEST, SET_LABEL_COLOR, FAILURE],
            body: { color },
        },
    };
}

export function copyFleetsToLabels(fleet_ids, label_ids) {
    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: API.fleets.copy_label,
            types: [REQUEST, COPY_FLEETS_TO_LABELS, FAILURE],
            body: {
                label_ids,
                fleet_ids,
            },
        },
    };
}

export function moveVehicles(cars, fleet_from_id, fleet_to_id) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.fleets.move_vehicle.replace(':id', fleet_from_id),
            types: [REQUEST, MOVE_VEHICLES, FAILURE],
            body: {
                car_ids: cars,
                fleet_from_id,
                fleet_to_id,
            },
        },
    };
}

export function copyVehiclesToFleets(cars, fleet_ids) {
    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: API.fleets.copy_cars,
            types: [REQUEST, COPY_VEHICLES, FAILURE],
            body: {
                car_ids: cars.map(id => parseInt(id, 10)),
                fleet_ids,
            },
        },
    };
}

export function removeFleets(fleetIds, labelID, oldFleetIds) {
    return {
        [CALL_API]: {
            method: 'PATCH',
            endpoint: API.labels.detail.replace(':id', labelID),
            types: [REQUEST, REMOVE_FLEETS, FAILURE],
            body: {
                fleet_ids: oldFleetIds.filter(id => !fleetIds.includes(id)),
            },
            oldFleetIds,
        },
    };
}

export function removeVehicles(vehicleIds, fleetID, oldVehicleIds) {
    const isFwee = getParsedLSItemOr('is_fweewawe');
    fleetID = parseInt(fleetID, 10);

    return {
        [CALL_API]: {
            method: 'PATCH',
            endpoint: (isFwee ? API.fweewawe.fleets.detail : API.fleets.detail).replace(':id', fleetID),
            types: [REQUEST, REMOVE_VEHICLES, FAILURE],
            body: { car_ids: oldVehicleIds.filter(id => !vehicleIds.includes(id)) },
            oldVehicleIds,
            id: fleetID,
        },
    };
}

export function deleteFleets(fleetIDs) {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            method: 'DELETE',
            endpoint: isFwee ? API.fweewawe.fleets.list : API.fleets.list,
            types: [REQUEST, DELETE_FLEETS, FAILURE],
            body: fleetIDs,
            fleetIDs,
        },
    };
}

export function setFleetColumns(fleetColumns) {
    return {
        type: SET_FLEET_COLUMNS,
        fleetColumns,
    };
}

export function setVehiclesColumns(columns) {
    return {
        type: SET_VEHICLES_COLUMNS,
        columns,
    };
}

export function toggleVehicleColumn(field) {
    return {
        type: TOGGLE_VEHICLE_COLUMN,
        field,
    };
}

export function setVehicleNEDC(vehicle, nedc_emissions, file) {
    const body = {
        nedc_emissions,
        nedc_emissions_file: file,
    };

    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: getCarUpdateEndpoint(vehicle),
            types: [REQUEST, SET_VEHICLE_NEDC, FAILURE],
            body,
            id: vehicle.id,
        },
    };
}

export function setVehicleWLTP(vehicle, wltp_emissions, file) {
    const body = {
        wltp_emissions,
        wltp_emissions_file: file,
    };

    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: getCarUpdateEndpoint(vehicle),
            types: [REQUEST, SET_VEHICLE_WLTP, FAILURE],
            body,
            id: vehicle.id,
        },
    };
}

export function setVehicleClass(vehicle, vehicle_class, file) {
    const body = {
        vehicle_class,
        vehicle_class_file: file,
    };

    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: getCarUpdateEndpoint(vehicle),
            types: [REQUEST, SET_VEHICLE_CLASS, FAILURE],
            body,
            id: vehicle.id,
        },
    };
}

export function setVehicleLastTechInspection(vehicle, last_tech_inspection) {
    const body = { last_tech_inspection };

    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: getCarUpdateEndpoint(vehicle),
            types: [REQUEST, SET_VEHICLE_INSPECTION_LAST, FAILURE],
            body,
            id: vehicle.id,
        },
    };
}

export function setVehicleNextTechInspectionEnd(vehicle, next_tech_inspection_end) {
    const body = { next_tech_inspection_end };

    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: getCarUpdateEndpoint(vehicle),
            types: [REQUEST, SET_VEHICLE_INSPECTION_END, FAILURE],
            body,
            id: vehicle.id,
        },
    };
}

export function setVehicleNextTechInspectionStart(vehicle, next_tech_inspection_start) {
    const body = { next_tech_inspection_start };

    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: getCarUpdateEndpoint(vehicle),
            types: [REQUEST, SET_VEHICLE_INSPECTION_START, FAILURE],
            body,
            id: vehicle.id,
        },
    };
}

export function setVehicleCPVs(id, cpvs) {
    const body = { cpvs };

    return {
        [CALL_API]: {
            method: 'PUT',
            endpoint: API.vehicles.detail.replace(':id', id),
            types: [REQUEST, SET_VEHICLE_CPVS, FAILURE],
            body,
            id,
        },
    };
}

export function renameVehicle(id, name) {
    const isFwee = getParsedLSItemOr('is_fweewawe');

    return {
        [CALL_API]: {
            endpoint: (isFwee ? API.fweewawe.vehicles.detail : API.vehicles.detail).replace(':id', id),
            method: 'PATCH',
            body: { name: name || null },
            types: [REQUEST, RENAME_VEHICLE, FAILURE],
            id,
        },
    };
}

export function fetchNedcFile(id) {
    return {
        [CALL_API]: {
            endpoint: API.vehicles.nedc_file_car_data.replace(':id', id),
            method: 'GET',
            types: [REQUEST, GET_VEHICLE_FILE, FAILURE],
            showError: true,
        },
    };
}

export function fetchWltpFile(id) {
    return {
        [CALL_API]: {
            endpoint: API.vehicles.wltp_file_car_data.replace(':id', id),
            method: 'GET',
            types: [REQUEST, GET_VEHICLE_FILE, FAILURE],
            showError: true,
        },
    };
}

export function setVehiclePlate(id, plate) {
    return {
        [CALL_API]: {
            endpoint: API.vehicles.convert.replace(':id', id),
            method: 'PUT',
            types: [REQUEST, SET_VEHICLE_PLATE, FAILURE],
            body: { car_data: { plate } },
            id,
        },
    };
}

export function fetchClassFile(car) {
    const endpoint = car.car_data_id
        ? API.vehicles.class_file_car_data.replace(':id', car.car_data_id)
        : API.vehicles.class_file_car_spec.replace(':id', car.car_spec_id);

    return {
        [CALL_API]: {
            endpoint,
            method: 'GET',
            types: [REQUEST, GET_VEHICLE_FILE, FAILURE],
            showError: true,
        },
    };
}

export function setVehicleFuel(vehicle, fuel_type) {
    const body = new FormData();
    body.append('fuel_type', fuel_type);

    return {
        [CALL_API]: {
            endpoint: getCarUpdateEndpoint(vehicle),
            method: 'PATCH',
            types: [REQUEST, SET_VEHICLE_FUEL, FAILURE],
            body,
            headers: API.fileHeaders,
            id: vehicle.id,
        },
    };
}

export function generateQrCodes(body) {
    return {
        [CALL_API]: {
            endpoint: API.vehicles.generate_qr,
            method: 'POST',
            types: [REQUEST, GENERATE_QR_CODES, FAILURE],
            responseType: 'blob',
            body,
        },
    };
}

export function setVehicleProcuredAt(id, procured_at) {
    return {
        [CALL_API]: {
            method: 'PATCH',
            endpoint: API.vehicles.detail.replace(':id', id),
            types: [REQUEST, SET_VEHICLE_PROCURED_AT, FAILURE],
            body: { procured_at },
            id,
        },
    };
}

export function getFleetPDF(id) {
    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.fleets.pdf.replace(':id', id),
            types: [REQUEST, GET_FLEET_PDF, FAILURE],
            responseType: 'blob',
        },
    };
}

export function getCPVs() {
    return {
        [CALL_API]: { method: 'GET', endpoint: API.vehicles.initial, types: [REQUEST, GET_CPVS, FAILURE] },
    };
}

export function getCustomers() {
    return {
        [CALL_API]: { method: 'GET', endpoint: API.groups, types: [REQUEST, GET_CUSTOMERS, FAILURE] },
    };
}

export function shareFleet(fleet_ids, organization_id, company_id) {
    fleet_ids = [fleet_ids]; // Backend expects array, but sharing is only implemented for 1 fleet at a time.

    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.fleets.share_send,
            types: [REQUEST, SHARE_FLEET, FAILURE],
            body: { fleet_ids, organization_id, company_id },
        },
    };
}

export function acceptPendingFleet(fleet_ids) {
    fleet_ids = [fleet_ids]; // Backend expects array, but sharing is only implemented for 1 fleet at a time.

    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.fleets.share_accept,
            types: [REQUEST, ACCEPT_SHARED_FLEET, FAILURE],
            body: { fleet_ids },
        },
    };
}

export function rejectPendingFleet(fleet_ids) {
    fleet_ids = [fleet_ids]; // Backend expects array, but sharing is only implemented for 1 fleet at a time.

    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.fleets.share_reject,
            types: [REQUEST, REJECT_SHARED_FLEET, FAILURE],
            body: { fleet_ids },
        },
    };
}

export function getCarDataSources(id) {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.vehicles.car_data_sources.replace(':id', id),
            types: [REQUEST, GET_CAR_DATA_SOURCES, FAILURE],
        },
    };
}

export function getCarSpecSources(id) {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.vehicles.car_spec_sources.replace(':id', id),
            types: [REQUEST, GET_CAR_SPEC_SOURCES, FAILURE],
        },
    };
}

export function getTraficomKinds() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.vehicles.traficom_kinds,
            types: [REQUEST, GET_TRAFICOM_KINDS, FAILURE],
        },
    };
}

export function getCarDataByKind(car, kind_id) {
    const { plate } = car;

    return {
        [CALL_API]: {
            method: 'POST',
            endpoint: API.vehicles.data,
            types: [REQUEST, GET_CAR_DATA_BY_KIND, FAILURE],
            body: { plate, kind_id },
            showError: true,
        },
    };
}
