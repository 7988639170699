import { createContext } from 'react';
import {
    SET_VEHICLE_WLTP,
    SET_VEHICLE_NEDC,
    SET_VEHICLE_CLASS,
    SET_VEHICLE_FUEL,
    SET_VEHICLE_PLATE,
    RENAME_VEHICLE,
} from 'components/Fleets/actions.js';
import {
    applyFilters,
    COLUMNS,
    sortVehicles,
    toggleColumnField,
    updateColumnSorts,
    formatCarObject,
} from '../Fleets/utils.js';
import { CHANGE_LOCALE } from '../layouts/actions';
import {
    REQUEST,
    FAILURE,
    GET_REPORT_TEMPLATES,
    DELETE_REPORT_TEMPLATES,
    GET_EVENTS,
    GET_MILEAGE_CARS,
    GET_MILEAGE_FLEETS,
    SET_MILEAGE_VEHICLES_COLUMNS,
    SET_MILEAGE_VEHICLES_SEARCH,
    SET_MILEAGE_VEHICLE_SORT,
    TOGGLE_MILEAGE_VEHICLE_COLUMN,
    SET_MILEAGES,
    GET_REPORT,
} from './actions.js';

export const MileageReportContext = createContext({});
export const GeneralVehicleReportContext = createContext({});
export const VehicleEmissionsReportContext = createContext({});
export const SingleFleetReportContext = createContext({});
export const GeneralFleetReportContext = createContext({});
export const SelectedVehiclesReportContext = createContext({});
export const GeneralMileageEmissionReportContext = createContext({});
export const ChosenVehiclesMileageReportContext = createContext({});
export const WorkingMachineReportContext = createContext({});
export const CVDReportContext = createContext({});

const parseColumn = col => ({ ...COLUMNS()[col], order: 1 });

export const initialColumns = () => [
    parseColumn('plate'),
    parseColumn('nedc_emissions'),
    parseColumn('make'),
    parseColumn('model'),
    parseColumn('fuel_type'),
    parseColumn('type'),
];

const initialState = {
    reportTemplates: [],
    events: {},
    fleets: [],
    allVehicles: [],
    listVehicles: [],
    columns: initialColumns(),
    sort: 'plate',
    searchText: '',
};

function mileage(state = initialState, action) {
    switch(action.type) {
        case REQUEST: {
            return { ...state, loading: true };
        }
        case FAILURE: {
            return {
                ...state,
                loading: false,
                error: action.error,
            };
        }
        case GET_REPORT_TEMPLATES: {
            return { ...state, reportTemplates: action.response };
        }
        case GET_EVENTS: {
            return { ...state, events: action.response };
        }
        case GET_MILEAGE_CARS: {
            const { sort, searchText, columns } = state;

            return {
                ...state,
                allVehicles: action.response,
                listVehicles: applyFilters(action.response, searchText, sort, columns),
            };
        }
        case GET_MILEAGE_FLEETS: {
            return { ...state, fleets: action.response };
        }
        case SET_MILEAGE_VEHICLE_SORT: {
            const { sort, sortBy } = action;
            const { newColumns, columnNewOrder } = updateColumnSorts(state.columns, state.sort, sort);

            return {
                ...state,
                listVehicles: sortVehicles(state.listVehicles, sort, columnNewOrder, sortBy),
                sort,
                columns: newColumns,
            };
        }
        case SET_MILEAGE_VEHICLES_SEARCH: {
            const { searchText } = action;

            return {
                ...state,
                listVehicles: applyFilters(state.allVehicles, searchText, state.sort, state.columns),
                searchText,
            };
        }
        case SET_MILEAGE_VEHICLES_COLUMNS: {
            return { ...state, columns: action.columns };
        }
        case CHANGE_LOCALE: {
            // TODO: set current columns in a way it runs the translations
            return { ...state, columns: initialColumns() };
        }
        case TOGGLE_MILEAGE_VEHICLE_COLUMN: {
            const { field } = action;
            const { allVehicles, columns, sort, searchText } = state;
            const { newSort, newColumns } = toggleColumnField(columns, sort, field);

            return {
                ...state,
                columns: newColumns,
                sort: newSort,
                listVehicles: applyFilters(allVehicles, searchText, newSort, newColumns),
            };
        }
        case GET_REPORT: {
            return { ...state, report: action.response };
        }

        case SET_MILEAGES: { return state; }
        case SET_VEHICLE_CLASS:
        case SET_VEHICLE_WLTP:
        case SET_VEHICLE_NEDC:
        case SET_VEHICLE_FUEL:
        case SET_VEHICLE_PLATE:
        case RENAME_VEHICLE: {
            const { id, response } = action;

            const updateCarInVehicles = v => (v.id === id ? { ...v, ...formatCarObject(response), id } : v);

            const listVehicles = state.listVehicles.map(updateCarInVehicles);
            const allVehicles = state.allVehicles.map(updateCarInVehicles);

            return {
                ...state,
                listVehicles,
                allVehicles,
            };
        }
        case DELETE_REPORT_TEMPLATES: {
            const reportTemplates = state.reportTemplates.filter(v => !action.ids.includes(v.id));

            return { ...state, reportTemplates };
        }
        default: { return state; }
    }
}

export default mileage;
