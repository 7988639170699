import { Workbox } from 'workbox-window';
import { t } from 'ttag';
import { API } from '../constants.js';
import { CALL_API } from '../middleware/api';
import { SHOW_NOTIFICATION_MODAL, UPDATE_SW } from '../components/layouts/actions';

let store = null;

export const urlB64ToUint8Array = base64String => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for(let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};

export const getPublicKey = () => new Promise((resolve, reject) => {
    store.dispatch({
        [CALL_API]: {
            endpoint: API.push,
            authenticated: false,
            showSuccess: false,
            showError: false,
            method: 'GET',
            types: ['SW_PUBLIC_KEY_REQUEST', 'SW_PUSH_PUBLIC_KEY', 'SW_PUBLIC_KEY_FAILURE'],
        },
    }).then(() => {
        resolve(store.getState().ServiceWorker.push.public_key);
    }).catch(err => {
        reject(err);
    });
});

export const registerSubscription = subscription => new Promise((resolve, reject) => {
    store.dispatch({
        [CALL_API]: {
            endpoint: API.push,
            authenticated: store.getState().Auth.isAuthenticated,
            method: 'POST',
            showSuccess: false,
            showError: false,
            body: {
                subscription: JSON.stringify(subscription),
            },
            types: [
                'SW_REGISTER_SUBSCRIPTION_REQUEST',
                'SW_REGISTER_SUBSCRIPTION_SUCCESS',
                'SW_REGISTER_SUBSCRIPTION_FAILURE',
            ],
        },
    }).then(() => {
        resolve();
    }).catch(err => {
        reject(err);
    });
});

export const createServiceWorker = reduxStore => {
    if('serviceWorker' in navigator && process.env.NODE_ENV !== 'development') {
        store = reduxStore;
        window.addEventListener('load', () => {
            const wb = new Workbox('/service-worker-custom.js');

            wb.addEventListener('waiting', () => {
                store.dispatch({
                    type: SHOW_NOTIFICATION_MODAL,
                    title: t`UPDATE_VERSION`,
                    body: t`UPDATE_VERSION`,
                    options: { onConfirm: () => store.dispatch({ type: UPDATE_SW }) },
                });

                let currentValue = store.getState().Layout.notificationModal.update;

                const handleChange = () => {
                    const previousValue = currentValue;
                    currentValue = store.getState().Layout.notificationModal.update;

                    if(previousValue !== currentValue) {
                        wb.addEventListener('controlling', () => {
                            localStorage.clear();
                            window.location.reload();
                        });

                        wb.messageSW({ type: 'SKIP_WAITING' });
                    }
                };

                store.subscribe(handleChange);
            });

            wb.register().then(async swRegistration => {
                store.dispatch({
                    type: 'SW_SET',
                    serviceWorker: swRegistration,
                });

                /* if('PushManager' in window) {
                    const publicKey = await getPublicKey(store);
                    store.dispatch({
                        type: 'SW_PUSH_AVAILABLE',
                        manager: swRegistration.pushManager,
                        public_key: publicKey,
                    });

                    if(Notification.permission !== 'denied') {
                        swRegistration.pushManager.getSubscription()
                            .then(async subscription => {
                                const isSubscribed = !(subscription === null);

                                if(isSubscribed) {
                                    await registerSubscription(subscription);
                                    store.dispatch({
                                        type: 'SW_PUSH_ENABLED',
                                        subscription,
                                    });
                                } else if(localStorage.getItem('push_enabled') === 'true') {
                                    swRegistration.pushManager.subscribe({
                                        userVisibleOnly: true,
                                        applicationServerKey: urlB64ToUint8Array(publicKey),
                                    }).then(async subscription => {
                                        await registerSubscription(subscription);
                                        store.dispatch({
                                            type: 'SW_PUSH_ENABLED',
                                            subscription,
                                        });
                                    }).catch(() => {
                                        store.dispatch({
                                            type: 'SW_PUSH_UNAVAILABLE',
                                        });
                                    });
                                } else {
                                    store.dispatch({
                                        type: 'SW_PUSH_DISABLED',
                                        subscription,
                                    });
                                }
                            });
                    } else {
                        store.dispatch({
                            type: 'SW_PUSH_UNAVAILABLE',
                        });
                    }
                } */
            });
        });
    }
};

export default createServiceWorker;
