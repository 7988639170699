import { API } from 'constants.js';
import { CALL_API } from 'middleware/api.js';

export const REQUEST = 'REQUEST';
export const FAILURE = 'FAILURE';
export const LIST_BOOKINGS = 'LIST_BOOKINGS';
export const FIND_AVAILABLE_CARS = 'FIND_AVAILABLE_CARS';
export const LIST_USERS = 'LIST_USERS';
export const CREATE_NEW_BOOKING = 'CREATE_NEW_BOOKING';
export const DELETE_BOOKING = 'DELETE_BOOKING';

export function getBookings() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.booking.list,
            types: [REQUEST, LIST_BOOKINGS, FAILURE],
        },
    };
}

export function getBookingUsers() {
    return {
        [CALL_API]: {
            method: 'GET',
            endpoint: API.booking.users,
            types: [REQUEST, LIST_USERS, FAILURE],
        },
    };
}

export function getAvailableVehicles(body) {
    return {
        [CALL_API]: {
            endpoint: API.booking.cars,
            method: 'POST',
            types: [REQUEST, FIND_AVAILABLE_CARS, FAILURE],
            body,
        },
    };
}

export function createNewBooking(body) {
    return {
        [CALL_API]: {
            endpoint: API.booking.book,
            method: 'POST',
            types: [REQUEST, CREATE_NEW_BOOKING, FAILURE],
            body,
        },
    };
}
export function deleteBooking(id) {
    return {
        [CALL_API]: {
            endpoint: API.booking.update.replace(':id', id),
            method: 'DELETE',
            types: [REQUEST, DELETE_BOOKING, FAILURE],
            id,
        },
    };
}
