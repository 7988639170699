import axios from 'axios';
import { t } from 'ttag';
import { SHOW_NOTIFICATION_MODAL, showTopNotification } from 'components/layouts/actions.js';
import { upgradeUserTier } from 'components/Account/actions.js';
import { API } from '../constants';
import { ErrorMessages, SuccessMessages } from './messages';

const removeLocalStorageKeys = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('public_key');
    localStorage.removeItem('oauth2');
    localStorage.removeItem('user');
    window.location.reload();
};

export const showUpgradeModal = (dispatch, title, body) => {
    dispatch({
        type: SHOW_NOTIFICATION_MODAL,
        title,
        body,
        options: {
            onConfirm: localStorage.getItem('is_masteruser') === 'true'
                ? () => dispatch(upgradeUserTier())
                : undefined,
            confirmLabel: t`UPGRADE_TIER`,
        },
    });
};

/* eslint-disable import/no-mutable-exports */
export let isRefreshing = false;
export let apiQueue = [];
/* eslint-enable import/no-mutable-exports */


const processQueue = (error, token = null) => {
    apiQueue.forEach(prom => {
        if(error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    apiQueue = [];
};

axios.interceptors.response.use(response => response, error => {
    const oRequest = error.config;
    if(!error.response) {
        return Promise.reject(error);
    } else if(
        // TODO: remove 403 check when backend has their shit together (we get 401 from REFRESH_TOKEN_EXPIRED)
        (error.response.status === 401 || error.response.status === 403) &&
        !oRequest.retry &&
        error.response.data.error_key !== 'INCORRECT_CREDENTIALS') {
        if(['REFRESH_TOKEN_EXPIRED', 'REFRESH_TOKEN_DATA_INVALID'].includes(error.response.data.error_key)) {
            removeLocalStorageKeys();
            return Promise.reject();
        }

        if(isRefreshing) {
            return new Promise((resolve, reject) => {
                apiQueue.push({ resolve, reject });
            }).then(token => {
                const oauth2 = localStorage.getItem('oauth2');
                if(oauth2) {
                    oRequest.headers.Authorization = `OAuth2 ${token}`;
                } else {
                    oRequest.headers.Authorization = `Bearer ${token}`;
                }
                return axios(oRequest);
            }).catch(err => Promise.reject(err));
        }

        oRequest.retry = true;
        isRefreshing = true;

        return new Promise((resolve, reject) => {
            let data = {};
            const oauth2 = localStorage.getItem('oauth2');

            if(oauth2) {
                data = {
                    access_token: localStorage.getItem('access_token'),
                    oauth: localStorage.getItem('oauth2'),
                };
            } else {
                data = { refresh_token: localStorage.getItem('refresh_token') };
            }

            axios.post(
                API.auth.refresh,
                data,
                { headers: API.headers },
            ).then(tokenRefresh => {
                const { data } = tokenRefresh;
                localStorage.setItem('access_token', data.access_token);

                if(oauth2) {
                    localStorage.setItem('user', JSON.stringify(data.user));
                    axios.defaults.headers.common.Authorization = `OAuth2 ${data.access_token}`;
                    oRequest.headers.Authorization = `OAuth2 ${data.access_token}`;
                } else {
                    localStorage.setItem('refresh_token', data.refresh_token);
                    localStorage.setItem('public_key', data.public_key);
                    axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
                    oRequest.headers.Authorization = `Bearer ${data.access_token}`;
                }
                axios.defaults.headers.common.Authorization = `Bearer ${data.access_token}`;
                oRequest.headers.Authorization = `Bearer ${data.access_token}`;
                processQueue(null, data.access_token);
                resolve(axios(oRequest));
            }).catch(tokenErr => {
                processQueue(tokenErr, null);
                reject(tokenErr);
            }).then(() => {
                isRefreshing = false;
            });
        });
    }

    return Promise.reject(error);
});

/* eslint-enable */

function callApi(endpoint, method, body, headers, responseType) {
    const token = localStorage.getItem('access_token') || null;
    const oauth2 = localStorage.getItem('oauth2') || null;

    headers = headers || API.headers;

    if(token) {
        if(token) {
            headers.Authorization = oauth2 ? `OAuth2 ${token}` : `Bearer ${token}`;
        } else {
            throw new Error('No token found in localStorage!');
        }
    }
    return axios({
        url: endpoint,
        headers,
        method,
        data: body,
        responseType,
    }).then(response => Promise.resolve(response.data));
}

export const CALL_API = Symbol('CALL API');

export default store => next => action => {
    const callAPI = action[CALL_API];

    if(typeof callAPI === 'undefined') {
        return next(action);
    }

    const {
        endpoint,
        types,
        authenticated,
        method,
        body,
        showError,
        showSuccess,
        headers,
        responseType,
        showLoading = true,
        ...rest
    } = callAPI;

    const [requestType, successType, errorType] = types;

    showLoading && store.dispatch({ type: 'SET_LOADING', on: true });
    store.dispatch({ type: requestType });

    // Some muffucken human-readabel errorinos
    if(!endpoint) {
        console.warn('Invalid endpoint ', endpoint, ' passed to action with types ', types);
    }

    if(!method) {
        console.warn('Invalid method ', method, ' passed to action with types ', types);
    }

    return callApi(endpoint, method, body, headers, responseType)
        .then(response => {
            showLoading && store.dispatch({ type: 'SET_LOADING', on: false });

            if(showSuccess || (showSuccess === undefined && ['PUT', 'POST', 'DELETE', 'PATCH'].includes(method))) {
                const successText = SuccessMessages()[response.success_key] || SuccessMessages().GENERAL_SUCCESS;
                store.dispatch(showTopNotification({ text: successText, type: 'success' }));
            }

            next({
                response,
                authenticated,
                type: successType,
                ...rest,
            });

            return response;
        })
        .catch(err => {
            store.dispatch({ type: 'SET_LOADING', on: false });

            let errorText = ErrorMessages().GENERAL_ERROR;
            if(!err.response) { // err.response is not defined when the user is offline
                errorText = ErrorMessages().NETWORK_ERROR;
                // err.response is undefined also when an error occurs in an action or reducer.
                // The error is silent (not outputted to console) because of the catch, so we do it here manually.
                console.warn(err);
            } else {
                const error = err.response.data;
                if(error) {
                    errorText = ErrorMessages()[error.error_key] || error.error_key;
                }
                // This is copied from caas_webapp, 99% sure it's useless. No balls to delete though
                if(err.details && err.details[0] && err.details[0].code === 'invalid') {
                    errorText = ErrorMessages().EMAIL_INVALID;
                }
            }
            if(showError || (showError === undefined && ['PUT', 'POST', 'DELETE', 'PATCH'].includes(method))) {
                store.dispatch(showTopNotification({ text: errorText, type: 'error' }));
            }

            // If any request responds with 'CAR_REQUESTS_LIMIT_REACHED' -error,
            // show a modal to the user about hitting their car request limits.
            if(['CAR_AMOUNT_LIMIT_REACHED', 'CAR_REQUESTS_LIMIT_REACHED'].includes(err.response?.data.error_key)) {
                showUpgradeModal(store.dispatch, t`TIER_LIMIT_TITLE`, t`TIER_LIMIT_BODY`);
            }

            next({
                error: true,
                errorMessage: errorText,
                type: errorType,
                errorCode: err.response &&
                           err.response.status,
            });

            return ({
                error: true,
                errorMessage: errorText,
                detail: err?.response?.data?.detail,
                errorCode: err?.response?.data?.error_key,
            });
        });
};
